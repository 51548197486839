import React, { useEffect, useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { Footer } from "../desktop/footer/Footer";
import { PiSecurityCameraDuotone } from "react-icons/pi";
import { FaClock, FaDollarSign } from "react-icons/fa";
import { FaSquareParking, FaArrowRight, FaMessage, FaQuoteRight } from "react-icons/fa6";
import phoneRender from "../../assets/images/phoneRender.png";
import computerRender from "../../assets/images/desktopRender.png";
import promo from "../../assets/videos/promo.mp4";
import SplitType from "split-type";
import "../desktop/pages/home/home.css";
import SecOne from "./SecOne";

const LandingPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [automated, setAutomated] = useState(false);
    const screenHeight = window.innerHeight;
    let ratio = 0;

    useEffect(() => {
        // window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },);

    setTimeout(() => {
        setAutomated(true);
    }, 500);

    const handleScroll = () => {
        const position = window.pageYOffset;
        ratio = position / screenHeight;
        setScrollPosition(ratio);
    };

    const showOffSection = (title, image, buttonTitle, url) => {
        if (title === "Real Time Analytics") {
            return (
                <div className="show-off-section mt-8">
                    <h1 className="show-off-title text-white">{title}</h1>
                    <img src={image} alt="showOffImage" className="show-off-image" style={{ width: "450px" }} />
                    <Link to={url}><button className="show-off-button mt-4">{buttonTitle}</button></Link>
                </div>
            )
        } else {
            return (
                <div className="show-off-section mt-8">
                    <h1 className="show-off-title text-white">{title}</h1>
                    <img src={image} alt="showOffImage" className="show-off-image" />
                    <Link to={url}><button className="show-off-button">{buttonTitle}</button></Link>
                </div>
            )
        }
    }

    const testimonials = [
        {
            university: "Westmont University",
            quote: "If we had a system that could automatically track people’s license plates and tell us if they need to be ticketed… That would make our life so much easier.",
            date: "January 2024",
            entrance: "delay-[200ms]"
        },
        {
            university: "Clackamas Community College",
            quote: "If we could have a camera system that tracked the cars and where they went… Man, that would be cool",
            date: "March 2024",
            entrance: "delay-[300ms]"
        },
        {
            university: "Virginia Commonwealth University",
            quote: "If I had to guess, I would say that only about 25% of people parked illegally end up getting ticketed.",
            date: "May 2024",
            entrance: "delay-[400ms]"
        }
    ]

    const testimonialBlock = (university, quote, date, entrance) => {
        return (
            <div className={`testimonial-block h-auto flex flex-row opacity-0 transition-all ${scrollPosition > 1.2 ? `opacity-100 duration-700 ${entrance}` : null}`}>
                <div className="quote-container flex" scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={10}>
                    <FaMessage className="message-icon" />
                    <FaQuoteRight className="quote-icon" />
                </div>
                <div className="ml-16 my-auto">
                    <p className="quote">"{quote}"</p>
                    <p className="university">- {university}, {date}</p>
                </div>
            </div>
        )
    }

    const smallWidth = (scrollPosition * 8) + 70;

    useEffect(() => {
        if (automated) {
            // Split the text into individual characters
            new SplitType('.practice', { types: 'chars' });
            let characters = document.querySelectorAll('.practice .char');

            // Apply a staggered animation to each character
            characters.forEach((char, index) => {
                setTimeout(() => {
                    char.style.opacity = '0';
                    char.style.transition = 'opacity 0.5s';
                    setTimeout(() => {
                        char.style.opacity = '1';
                        char.style.backgroundImage = 'linear-gradient(to right, #FFE374, #FEF08A)';
                        char.style.backgroundClip = 'text';
                        char.style.webkitBackgroundClip = 'text';
                        char.style.color = 'transparent';
                        char.style.transform = 'translateY(-40px)';
                        char.style.transition = 'opacity 0.5s, transform 0.5s';
                    }, index * 50);
                }, index * 50);
            });
        }
    }, [automated]);

    return (
        <div className="flex justify-center align-middle flex-col h-auto w-screen bg-lightYellow">
            <SecOne />
            <div className="flex flex-row justify-center align-middle p-4 h-[580px] w-screen bg-lightYellow">
                <div className="fcc gap-4 mt-16 w-[45%] mb-24 md:min-w-[400px] min-w-[40%] ml-4">
                    <div className="frc">
                        {/* Cost Efficient */}
                        <div className={`opacity-0 transition-all ${scrollPosition > 0.4 ? `opacity-100 mt-0 duration-700 delay-[200ms]` : null}`}>
                            <div className="feature-icon-container text-spotGray text-3xl mb-6 group hover:scale-105 transition-all duration-300 ease-in-out">
                                <div className="group-hover:scale-x-[-1] transition-all duration-300 ease-in-out">
                                    <FaClock />
                                </div>
                            </div>
                            <h3 className="font-bold">Cost Efficient</h3>
                            <p className="feature-description">On average, Universities pay $400,000 on parking enforcement. With the Spot Parking system costs can be reduced by upwards of 65%!</p>
                            <div className="flex flex-row">
                                <Link to="/features#section4" className="feature-link">Learn More</Link>
                                <FaArrowRight className="feature-arrow self-center" />
                            </div>
                        </div>
                        {/* More Open Spaces */}
                        <div className={`opacity-0 transition-all ${scrollPosition > 0.4 ? `opacity-100 mt-0 duration-700 delay-[300ms]` : null}`}>
                            <div className="feature-icon-container text-spotGray text-3xl mb-6 group hover:scale-105 transition-all duration-300 ease-in-out">
                                <div className="group-hover:scale-x-[-1] transition-all duration-300 ease-in-out">
                                    <FaSquareParking />
                                </div>
                            </div>
                            <h3 className="font-bold">More Open Spaces</h3>
                            <p className="feature-description">By more efficiently enforcing parking polices, Spot Parking estimates opening up to 27% more parking spaces for those who actually purchased a parking pass.</p>
                            <div className="flex flex-row">
                                <Link to="/features#section4" className="feature-link">Learn More</Link>
                                <FaArrowRight className="feature-arrow self-center" />
                            </div>
                        </div>
                    </div>
                    <div className="frc">
                        {/* Cost Efficient */}
                        <div className={`opacity-0 transition-all ${scrollPosition > 0.4 ? `opacity-100 mt-0 duration-700 delay-[200ms]` : null}`}>
                            <div className="feature-icon-container text-spotGray text-3xl mb-6 group hover:scale-105 transition-all duration-300 ease-in-out">
                                <div className="group-hover:scale-x-[-1] transition-all duration-300 ease-in-out">
                                    <FaClock />
                                </div>
                            </div>
                            <h3 className="font-bold">Cost Efficient</h3>
                            <p className="feature-description">On average, Universities pay $400,000 on parking enforcement. With the Spot Parking system costs can be reduced by upwards of 65%!</p>
                            <div className="flex flex-row">
                                <Link to="/features#section4" className="feature-link">Learn More</Link>
                                <FaArrowRight className="feature-arrow self-center" />
                            </div>
                        </div>
                        {/* More Open Spaces */}
                        <div className={`opacity-0 transition-all ${scrollPosition > 0.4 ? `opacity-100 mt-0 duration-700 delay-[300ms]` : null}`}>
                            <div className="feature-icon-container text-spotGray text-3xl mb-6 group hover:scale-105 transition-all duration-300 ease-in-out">
                                <div className="group-hover:scale-x-[-1] transition-all duration-300 ease-in-out">
                                    <FaSquareParking />
                                </div>
                            </div>
                            <h3 className="font-bold">More Open Spaces</h3>
                            <p className="feature-description">By more efficiently enforcing parking polices, Spot Parking estimates opening up to 27% more parking spaces for those who actually purchased a parking pass.</p>
                            <div className="flex flex-row">
                                <Link to="/features#section4" className="feature-link">Learn More</Link>
                                <FaArrowRight className="feature-arrow self-center" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center align-middle">
                    <video
                        className="max-w-[800px] rounded-2xl transition-all duration-500 ease-in-out z-20 peer hover:mb-8 mr-4"
                        src={promo}
                        controls
                        autoPlay
                        muted
                        loop>
                    </video>
                    <div className="absolute flex w-[760px] h-24 ml-5 mt-[350px] mx-auto bg-gray-600 rounded-3xl transition-all duration-500 z-10 peer-hover:mt-[360px]"></div>
                    <div className="absolute flex w-[700px] h-24 ml-12 mt-[350px] mx-auto bg-gray-400 rounded-3xl transition-all duration-500 z-0 peer-hover:mt-[400px]"></div>
                </div>
            </div>
            <div className="flex flex-row h-fit p-4 pb-12 z-20 bg-gray-700 m-auto rounded-t-3xl up-lg" style={{ width: `${smallWidth}%` }}>
                {showOffSection("Real Time Analytics", computerRender, "See More", "/features#section4")}
                {showOffSection("Live Interactive Map", phoneRender, "Try it out!", "/features#section3")}
            </div>
            <div className="home-sec4 relative">
                <div className="m-auto h-16 bg-gray-700 rounded-b-3xl z-50 mb-12 shadow-2xl" style={{ width: `${smallWidth}%` }}></div>
                <h1 className={`testmonial-title opacity-0 transition-all ${scrollPosition > 1 ? `opacity-100 mt-0 duration-700 delay-100` : null}`}>University First Impressions</h1>
                <h1 className={`testmonial-subtitle mt-2 opacity-0 transition-all ${scrollPosition > 1.1 ? `opacity-100 mt-0 duration-700 delay-200` : null}`}>When asked about parking enforcement</h1>
                <div className="fcc gap-0">
                    {testimonials.map((currentTestimonial, index) => (
                        testimonialBlock(currentTestimonial.university, currentTestimonial.quote, currentTestimonial.date, currentTestimonial.entrance)
                    ))}
                </div>
            </div>
            <div className="flex-col-center">
                <Footer />
            </div>
        </div>
    )
}

export default LandingPage;