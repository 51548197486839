import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles.css';
// Website
import { Home } from './components/desktop/pages/home/Home';
import { About } from './components/desktop/pages/about/About';
import { Features } from './components/desktop/pages/features/Features';
import { ContactUs } from './components/desktop/pages/contactUs/ContactUs';
import { MHome } from './components/mobile/pages/home/MHome';
import { MAbout } from './components/mobile/pages/about/MAbout';
import { MFeatures } from './components/mobile/pages/features/MFeatures';
import { MContactUs } from './components/mobile/pages/contactUs/MContactUs';
import { Calculator } from './components/desktop/pages/Calculator';
import { MCalculator } from './components/mobile/pages/MCalculator';
import LandingPage from './components/landingPage/LandingPage';
import { Header } from './components/desktop/header/Header';
import { MHeader } from './components/mobile/header/MHeader';
import PrivacyPolicy from './components/desktop/pages/PrivacyPolicy';
import TermsAndConditions from './components/desktop/pages/TermsAndConditions';
import Download from './components/desktop/pages/Download';

function App() {
    const [state, setState] = useState(0);
    const width = window.innerWidth;
    const isMobile = width < 1024;

    useEffect(() => {
        const handleResize = () => {
            setState(state + 1);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [state]);

    const routes = [
        { path: '/', component: isMobile ? <MHome /> : <Home /> },
        { path: '/features', component: isMobile ? <MFeatures /> : <Features /> },
        { path: '/about-us', component: isMobile ? <MAbout /> : <About /> },
        { path: '/contact', component: isMobile ? <MContactUs /> : <ContactUs /> },
        { path: '/info', component: <LandingPage /> },
        { path: '/calculator', component: isMobile ? <MCalculator /> : <Calculator /> },
        { path: '/download', component: <Download /> },
        { path: '/privacy-policy', component: <PrivacyPolicy /> },
        { path: '/terms-and-conditions', component: <TermsAndConditions /> }
    ];

    return (
        <Router>
            {window.location.pathname !== '/download' && (isMobile ? <MHeader /> : <Header />)}
            <Routes>
                {routes.map(({ path, component }) => (
                    <Route key={path} path={path} element={component} />
                ))}
            </Routes>
        </Router>
    );
}

export default App;