import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { logo } from "../../../assets/logo";
import "./header.css";

export const Header = () => {
    const [currentLink, setCurrentLink] = useState("");
    const location = useLocation();

    useEffect(() => {
        setCurrentLink(location.pathname);
    }, [location]);


    const headerLink = (title, url) => {
        const isActive = currentLink === url ? "active" : "";
        if (title === "Log In" || title === "Demo") {
            return (
                <div className={`text-2xl ${isActive ? "text-spotYellow" : "text-white"}`}>
                    <a href={url} target="_blank" rel="noopener noreferrer" className="sub-link">{title}</a>
                </div>
            );
        }

        return (
            <div className={`text-2xl hover:text-spotYellow ${isActive ? "text-spotYellow" : "text-white"}`}>
                <Link to={url}>{title}</Link>
            </div>
        );
    };

    const headerLinks = [
        { title: "Home", url: "/" },
        { title: "Features", url: "/features" },
        { title: "About Us", url: "/about-us" },
        { title: "Contact", url: "/contact" },
        { title: "Download", url: "/download" },
        { title: "Log In", url: "https://admin.spotparking.app/login" }
    ];


    return (
        <header className="fixed flex flex-row justify-end top-0 h-14 w-screen text-white text-3xl font-bold z-50 bg-gray-700/20 backdrop-blur-lg font-rubik">
            <div className="mr-auto mt-1 ml-2 w-20">
                <Link to="/">
                    {logo(70, 50, "#FFE374")}
                </Link>
            </div>
            <div className="frc gap-8 mr-8">
                {headerLinks.map((link, index) => {
                    return headerLink(link.title, link.url);
                })}
                {/* <div className={`text-2xl hover:text-spotYellow ${currentLink === "/" ? "text-spotYellow" : "text-white"}`}>
                    <Link to="/">Home</Link>
                </div>
                <div className="text-2xl hover:text-spotYellow cursor-pointer group">
                    <Link to="/features">Products</Link>
                    <div className="hidden group-hover:flex group-hover:flex-col gap-1 justify-start absolute bg-gray-700/20 backdrop-blur-lg z-50 rounded-lg p-3 text-white text-lg text-left">
                        <Link className="hover:text-spotYellow" to="/admin">Administration</Link>
                        <Link className="hover:text-spotYellow" to="/enforcement">Enforcement</Link>
                        <Link className="hover:text-spotYellow" to="/nav">Navigation</Link>
                    </div>
                </div>
                <div className="text-2xl hover:text-spotYellow cursor-pointer group">
                    <Link to="/industries">Industries</Link>
                    <div className="hidden group-hover:flex group-hover:flex-col gap-1 justify-start absolute bg-gray-700/20 backdrop-blur-lg z-50 rounded-lg p-3 text-white text-lg text-left">
                        <Link className="hover:text-spotYellow" to="/admin">Education</Link>
                        <Link className="hover:text-spotYellow" to="/enforcement">Hourly Parking</Link>
                        <Link className="hover:text-spotYellow" to="/nav">Free Parking</Link>
                    </div>
                </div>
                <div className={`text-2xl hover:text-spotYellow ${currentLink.includes("about") ? "text-spotYellow" : "text-white"}`}>
                    <Link to="/about-us">About Us</Link>
                </div>
                <div className={`text-2xl hover:text-spotYellow ${currentLink.includes("contact") ? "text-spotYellow" : "text-white"}`}>
                    <Link to="/contact">Contact</Link>
                </div>
                <div className={`text-2xl text-white`}>
                    <a href="https://admin.spotparking.app/login" target="_blank" rel="noopener noreferrer" className="sub-link">Demo</a>
                </div> */}
            </div>
        </header>
    );
}