import React, { useState, useEffect } from "react";
import { MHeader } from "../../header/MHeader";
import emailjs from "emailjs-com";
import { FaCaretRight, FaCaretDown } from "react-icons/fa6";
import { Toaster, toast } from 'sonner';
import { MFooter } from "../../footer/MFooter";
import "./mContactUs.css";

export const MContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [submitMessage, setSubmitMessage] = useState("Submit");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name || !email || !phone || !message) {
            toast.error("Make sure to fill out all the fields!");
            return;
        }

        setSubmitMessage("Message has been sent!");

        const templateParams = {
            name,
            email,
            phone,
            message
        };

        // Send email using emailjs
        emailjs
            .send(
                "service_dsu84xg",
                "template_ho732mv",
                templateParams,
                "_mhIZPOql0BHuIkf1"
            )
            .then((response) => {
                console.log("Email sent successfully!");
            })
            .catch((error) => {
                console.error("Error sending email:", error);
            });

        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");

        setTimeout(() => {
            setSubmitMessage("Submit Another Message");
        }, 2000);
    }

    const FAQSection = () => {
        const [activeDropdown, setActiveDropdown] = useState(null);

        const toggleDropdown = (index) => {
            setActiveDropdown(index === activeDropdown ? null : index);
        };

        const faqData = [
            {
                question: "What to do if i'm interested in learning more about Spot Parking?",
                answer: "If you have a specific question feel free to fill out the help form below or contact help@spotparking.app and we would be happy to help you."
            },
            {
                question: "How do I create an account?",
                answer: "Accounts will be created by Spot Parking in the onboarding process of the University. If you are interested in onboarding your university with the Spot Parking System, contact info@spotparking.app."
            },
            {
                question: "Is the app for students?",
                answer: "No, currently the app is for parking enforcement employees. A student app is on the way!"
            },
            {
                question: "How accurate is the object detection software?",
                answer: "Currently, the Spot Parking detection system has a 97% accuracy rate. This number is only increasing as the Spot Parking's AI continues to improve."
            },
            {
                question: "How does the system detect if my car has a parking pass?",
                answer: "At the entrance of each parking lot there is a license plate reader camera that scans vehicle's plate as it enters the  lot. The license plate number is then check in the school database to determine if there is a parking pass associated with the vehicle."
            }
        ];

        return (
            <div className="m-cu-faq-section">
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className={`m-cu-faq-item ${activeDropdown === index ? "active" : ""}`}
                        onClick={() => toggleDropdown(index)}
                    >
                        <div className="m-cu-faq-question-container">
                            {activeDropdown === index ? <FaCaretDown className="m-cu-faq-icon" /> : <FaCaretRight className="m-cu-faq-icon" />}
                            <p className="m-cu-faq-question text-lg">{faq.question}</p>
                        </div>
                        {activeDropdown === index && <p className="m-cu-faq-answer">{faq.answer}</p>}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="m-cu no-scrollbar font-rubik">
            <MHeader />
            <div className="flex-col-center mx-8">
                <div className="flex-col-center w-full">
                    <h1 className="flex text-3xl font-bold mt-24">Have More Questions?</h1>
                    <p className="flex text-xl font-medium mt-2 mb-8">We’d love to hear from you</p>
                </div>
                <form className="flex-col-center w-[90%]" onSubmit={handleSubmit}>
                    <input className="m-cu-input w-full" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <input className="m-cu-input w-full" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input className="m-cu-input w-full" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    <textarea className="m-cu-textarea w-full" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                    <button className="w-full text-spotGray font-bold rounded-lg shadow-md yellow-gradient py-2">{submitMessage}</button>
                </form>
                <div className="flex-col-center w-full mb-8">
                    <div className="flex text-2xl font-bold mt-8 mb-4">Frequently Asked Questions</div>
                    <FAQSection />
                </div>
                <MFooter />
            </div>
            <Toaster />
        </div>
    )
}