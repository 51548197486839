import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa6";

const Arrow = () => {
    const [arrow, setArrow] = useState(false);
    const [pulse, setPulse] = useState(false);
    const screenHeight = window.innerHeight - 100;

    setTimeout(() => {
        setArrow(true);
    }, 2000);

    setTimeout(() => {
        setPulse(true);
    }, 2500);

    function handleClick() {
        window.scrollTo({
            top: screenHeight,
            behavior: "smooth",
        });
    }

    return (
        <div className={`absolute left-1/2 transform -translate-x-1/2 cursor-pointer flex justify-center items-center w-16 h-16 rounded-full bg-gray-200/10 backdrop-blur-sm bottom-8 transition-all duration-700 ${arrow ? "opacity-100" : "opacity-0"} ${pulse ? "animate-pulse" : "null"}`} onClick={handleClick}>
            <FaArrowRight className="text-white text-4xl m-auto rotate-90 opacity-50" />
        </div>
    );
}

export default Arrow;