import React, { useState, useRef } from "react";
import TitleCard from "./TitleCard";
import "./ViolationCard.css";

const ViolationCard = ({ violation, onClose }) => {
    const cardRef = useRef(null);
    const headerRef = useRef(null);
    const [startY, setStartY] = useState(0);
    const [currentY, setCurrentY] = useState(0);

    //Touch event handlers
    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
        setCurrentY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        setCurrentY(e.touches[0].clientY);
        const offsetY = currentY - startY;
        if (offsetY < 0) {
            cardRef.current.style.transform = `translateY(${offsetY}px)`;
        }
    };

    const handleTouchEnd = () => {
        const offsetY = currentY - startY;
        if (offsetY < -100) {
            cardRef.current.style.transform = `translateY(-55vh)`;
        } else {
            cardRef.current.style.transform = `translateY(0)`;
        }
    };

    // const handleContentClick = (e) => {
    //     e.stopPropagation();
    // };

    const handleClose = () => {
        onClose();
    };

    return (
        <div
            className="z-50 absolute left-36 bottom-4"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div ref={cardRef} className="h-64 w-[400px] bg-white/20 backdrop-blur-md rounded-t-3xl">
                <button className="absolute right-4 top-2 text-lg bg-gray-200 hover:bg-gray-300 h-8 w-8 justify-center items-center rounded-full" onClick={handleClose}>
                    &#x2715;
                </button>
                <div
                    ref={headerRef}
                    onTouchStart={handleTouchStart}
                >
                    <TitleCard violation={violation} />
                </div>
            </div>
        </div>
    );
};

export default ViolationCard;
