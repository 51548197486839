import React, { useState, useEffect } from "react";
import x from "../../../../assets/images/x.png"; //TODO: Remove these and use icons instead of images
import linkedIn from "../../../../assets/images/linkedIn.png";
import instagram from "../../../../assets/images/instagram.png";
import sioy from "../../../../assets/images/sioy.jpg";
import uec from "../../../../assets/images/uec.jpg";
import ihub from "../../../../assets/images/ihub.jpg";
import bereal from "../../../../assets/images/bereal.jpg";
import byuLogo from "../../../../assets/images/byuLogo.jpg";
import dean from "../../../../assets/images/dean.png";
import ryan from "../../../../assets/images/ryan.png";
import cooper from "../../../../assets/images/cooper.png";
import { MFooter } from "../../footer/MFooter";
import "./mAbout.css";
import "../../../../assets/ReactParallaxTilt.css";

export const MAbout = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const screenHeight = window.innerHeight;
    let ratio = 0;
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        ratio = position / screenHeight;
        setScrollPosition(ratio);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },);

    const Dot = () => {
        const dotStyle = {
            width: 40,
            height: 40,
            left: 16,
            top: "25vh",
            position: 'relative',
            background: 'var(--yellow)',
            borderRadius: 9999,
            zIndex: 1
        }

        return (
            <div>
                <div style={dotStyle}></div>
            </div>
        )
    }

    const FounderCard = (name) => {
        const founderList = {
            "Dean Smith": {
                image: dean,
                text: "Dean is studying computer engineering at the University of Utah. As Spot Parking's CTO he uses his extensive background in programming to develop the company's backend software. Dean served a two-year mission in Albuquerque, New Mexico where him and Ryan met.",
                xLink: "https://twitter.com/spotparking_",
                linkedInLink: "https://www.linkedin.com/in/dean-smith-508bab173/",
                instagramLink: "https://www.instagram.com/deanasmith607/"
            },
            "Ryan Hagerty": {
                image: ryan,
                text: "Ryan is a co-founder of Spot Parking and is currently serving as the CEO. He is studying business at Brigham Young University and hopes to focus his efforts on information systems. Ryan served a two-year mission in Albuquerque, New Mexico and is passionate about using technology to solve real world problems.",
                xLink: "https://twitter.com/ryandhagerty",
                linkedInLink: "https://www.linkedin.com/in/ryandhagerty/",
                instagramLink: "https://www.instagram.com/ryan.hagerty/"
            },
            "Cooper Young": {
                image: cooper,
                text: "Cooper grew up in Spokane, Washington, where he met Ryan. He is one of seven in his family, including his twin sister Maci. Cooper served a two-year mission in Oaxaca, Mexico and returned in July of 2023. He is currently pursuing a degree in information systems from Brigham Young University and serves as Spot Parking's COO.",
                xLink: "https://twitter.com/spotparking_",
                linkedInLink: "https://www.linkedin.com/in/cooperyoungbyu/",
                instagramLink: "https://www.instagram.com/cooper.eli.young/"
            },
        }


        return (
            <div className="flex flex-row mb-4">
                <div className="flex flex-col">
                    <img src={founderList[name].image} alt="founder" className="object-contain rounded-lg" />
                    <p className="font-bold text-lg text-center">{name}</p>
                    <div className="flex flex-row justify-between w-[90%] self-center">
                        <div>
                            <a href={founderList[name].linkedInLink} target="_blank" rel="noopener noreferrer">
                                <img src={linkedIn} alt={"LinkedIn"} className="object-contain w-4 mt-1" />
                            </a>
                        </div>
                        <div>
                            <a href={founderList[name].instagramLink} target="_blank" rel="noopener noreferrer">
                                <img src={instagram} alt={"Instagram"} className="object-contain w-4 mt-1" />
                            </a>
                        </div>
                        <div>
                            <a href={founderList[name].xLink} target="_blank" rel="noopener noreferrer">
                                <img src={x} alt={"X"} className="object-contain w-4 mt-1" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="m-founder-bottom">
                    <p className="m-founder-text">{founderList[name].text}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="m-about no-scrollbar font-rubik">
            <div className="m-sec1">
                <div className="line"></div>
                <div style={{ marginTop: "8vh" }}>
                    {Object.keys(itemList).map((key, index) => {
                        return (
                            <div className={`m-sec1-page`}>
                                {Dot()}
                                <div className={`m-about-tile-container transition-all duration-200 ${scrollPosition >= itemList[key].entrance ? `opacity-100` : "opacity-0"}`}>
                                    <div className="text-3xl font-bold fcc p-3">{itemList[key].date}</div>
                                    <img src={itemList[key].image} alt={key} className="flex w-full object-cover h-fit" />
                                    <div className="p-4 text-lg">{itemList[key].text}</div>
                                    <div>{itemList[key].learnMoreLink ? <a href={itemList[key].learnMoreLink} className="m-learn-more-link">Learn More</a> : null}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="flex flex-col bg-lightYellow p-4 z-30">
                <div className="text-4xl w-screen text-center font-bold py-4">Meet the Founders</div>
                <div className="flex flex-col h-fit w-screen p-6">
                    {FounderCard("Ryan Hagerty")}
                    {FounderCard("Dean Smith")}
                    {FounderCard("Cooper Young")}
                </div>
            </div>
            <MFooter textColor={"text-spotGray"} background={"bg-lightYellow"} />
        </div>
    )
}

const itemList = {
    "BeReal": {
        image: bereal,
        date: "September 1st, 2023",
        text: "Spot Parking became a registered LLC in the state of Utah at Cooper, Ryan, and Dean’s first in-person meeting after serving two year missions for their church.",
        entrance: 0
    },
    "BYU": {
        image: byuLogo,
        date: "January 9th, 2024",
        text: "Over Near Year's break including from 10PM-1AM on New Years Eve, the team created a presentation that was later given to memebrs of BYU's security team. This was the beginning of Spot's relationship with BYU. ",
        entrance: 0.1
    },
    "SIOY": {
        image: sioy,
        date: "February 29th, 2024",
        text: "Spot Parking competed in the Student Innovator of the Year competition at Brigham Young University. Taking on 54 other businesses they were victorious, winning $12,000! ",
        learnMoreLink: "https://news.byu.edu/intellect/innovative-group-of-byu-students-roll-out-new-ai-tech-to-solve-parking-problems",
        entrance: 0.4
    },
    "iHUB": {
        image: ihub,
        date: "March 12th, 2024",
        text: "Becoming the first business to be admitted into The Innovation Hub of Utah, the entrepreneurship incubator in Utah county accelerated Spot Parking’s growth and expansion.",
        learnMoreLink: "https://www.ihubutah.org/",
        entrance: 0.7
    },
    "UEC": {
        image: uec,
        date: "March 23rd, 2024",
        text: "At the Utah Entrepreneurship Challenge, 20 businesses from 10 different universities across the state of Utah competed by presenting their startups to a panel of judges. Spot Parking was selected to represent BYU and took 3rd winning $8,000! ",
        learnMoreLink: "https://lassonde.utah.edu/powder-baby-dry-shampoo-wins-30000-grand-prize-at-2024-utah-entrepreneur-challenge/",
        entrance: 1
    },
}