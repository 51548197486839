import React from "react";
import { Link } from "react-router-dom";
import { FaXTwitter, FaLinkedin } from "react-icons/fa6";
import Tilt from 'react-parallax-tilt';
import "./mFooter.css"

export const MFooter = ({ textColor, background }) => {
    const footerLinks = [
        { title: "LinkedIn", url: "https://www.linkedin.com/company/spotparking/", icon: <FaLinkedin className={`text-3xl ${textColor}`} /> },
        { title: "X", url: "https://twitter.com/spotparking_", icon: <FaXTwitter className={`text-3xl ${textColor}`} /> },
    ];

    return (
        <div className={`flex-col-center w-full p-2 ${background}`}>
            <div className="flex-row-center gap-1">
                {footerLinks.map((link, index) => (
                    <Tilt key={index} scale={1.1} tiltMaxAngleX={5} tiltMaxAngleY={5}>
                        <Link to={link.url} className="m-logo-link">
                            {link.icon}
                        </Link>
                    </Tilt>
                ))}
            </div>
            <p className={`m-copyright ${textColor}`} >&copy; 2024 Spot Parking. All rights reserved.</p>
        </div>
    )
}