import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// import logo from "../../../assets/images/logo.png";
import "./mHeader.css";

export const MHeader = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const headerLink = (title, url) => {
        const currentPath = window.location.pathname;
        const isActive = currentPath === url ? "active" : "";

        if (title === "Demo") {
            return (
                <div className={`font-bold mb-1 ${isActive ? "text-spotYellow" : "text-spotGray"} text-spotGray`}>
                    <a href={url} target="_blank" rel="noopener noreferrer">{title}</a>
                </div>
            );
        }

        return (
            <div className={`font-bold mb-4 ${isActive ? "text-spotYellow" : "text-spotGray"} text-spotGray`} onClick={() => setSidebarOpen(false)}>
                <Link to={url}>{title}</Link>
            </div>
        );
    };

    useEffect(() => {
        // Add event listener to the document object
        document.addEventListener('mousedown', handleClickOutside);

        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (
            sideNavRef.current &&
            !sideNavRef.current.contains(event.target) &&
            hamburgerButtonRef.current &&
            !hamburgerButtonRef.current.contains(event.target) // Check if the event target is outside the hamburger button
        ) {
            setSidebarOpen(false);
            console.log("click outside");
        }
    };

    const handleHamburgerClick = () => {
        setSidebarOpen(!sidebarOpen);
    }

    const sideNavRef = useRef(null);
    const hamburgerButtonRef = useRef(null);

    const headerLinks = [
        { title: "Home", url: "/" },
        { title: "Features", url: "/features" },
        { title: "About Us", url: "/about-us" },
        { title: "Contact Us", url: "/contact" },
        { title: "Download", url: "/download" }
    ];

    const lowerLinks = [
        { title: "Log In", url: "https://mobile.spotparking.app" }
    ]

    return (
        <header className="fixed flex flex-row justify-end top-0 w-screen h-16 bg-spotGray/20 z-50 backdrop-blur-md text-white shadow-lg font-rubik" ref={sideNavRef}>
            <button className="group fixed w-8 h-8 top-4 left-4 z-50" onClick={handleHamburgerClick} ref={hamburgerButtonRef}>
                <div className="grid justify-items-center gap-1.5" ref={sideNavRef}>
                    <span className={`h-1 w-8 rounded-full ${sidebarOpen ? "bg-black rotate-45 translate-y-2.5" : "bg-white"} transition duration-700`}></span>
                    <span className={`h-1 w-8 rounded-full ${sidebarOpen ? "bg-black scale-x-0" : "bg-white"} duration-[0.5s] transition`}></span>
                    <span className={`h-1 w-8 rounded-full ${sidebarOpen ? "bg-black -rotate-45 -translate-y-2.5" : "bg-white"} transition duration-700`}></span>
                </div>
            </button>
            <div className={`fixed flex flex-col top-0 left-0 h-screen w-[62vw] bg-white text-white shadow-lg -translate-x-full transition-transform duration-500 z-30 ${sidebarOpen ? "translate-x-0" : null}`} ref={sideNavRef}>
                <div className="text-4xl text-spotGray mt-20 ml-4 mb-4">
                    {headerLinks.map((link, index) => {
                        return headerLink(link.title, link.url);
                    })}
                </div>
                <hr className="flex place-self" />
                <div className="text-4xl text-spotGray m-4">
                    {lowerLinks.map((link, index) => {
                        return headerLink(link.title, link.url);
                    })}
                </div>
            </div>
            <div className={"flex justify-center items-center h-8 bg-white px-5 mr-6 mt-4 rounded-md font-bold shadow-lg text-spotGray hover:bg-gray-500"}>
                <a href={"http://mobile.spotparking.app/login"} target="_blank" rel="noopener noreferrer">Log In</a>
            </div>
        </header>
    );
}