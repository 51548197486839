import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { FaCaretRight, FaCaretDown } from "react-icons/fa6";
import { Toaster, toast } from 'sonner';
import { Footer } from "../../footer/Footer";
import "./contactUs.css";
import { Helmet } from "react-helmet-async";
import Arrow from "../../Arrow";

export const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const handleScroll = () => {
            setScrollPosition(window.pageYOffset / window.innerHeight);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const radius = Math.round((scrollPosition * 120));
    const width = 100 - (scrollPosition * 10);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [submitMessage, setSubmitMessage] = useState("Submit");
    const [activeDropdown, setActiveDropdown] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name || !email || !phone || !message) {
            toast.error("Make sure to fill out all the fields!");
            return;
        }

        setSubmitMessage("Message has been sent!");

        const templateParams = {
            name,
            email,
            phone,
            message
        };

        // Send email using emailjs
        emailjs
            .send(
                "service_dsu84xg",
                "template_ho732mv",
                templateParams,
                "_mhIZPOql0BHuIkf1"
            )
            .then((response) => {
                console.log("Email sent successfully!");
            })
            .catch((error) => {
                console.error("Error sending email:", error);
            });

        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");

        setTimeout(() => {
            setSubmitMessage("Submit Another Message");
        }, 2000);
    }

    const FAQSection = () => {
        const toggleDropdown = (index) => {
            setActiveDropdown(index === activeDropdown ? null : index);
        };

        const faqData = [
            {
                question: "How do I learn more about Spot Parking?",
                answer: "If you have a specific question feel free to fill out the help form above or contact help@spotparking.app and we would be happy to help you."
            },
            {
                question: "How do I create an account?",
                answer: "Accounts will be created by Spot Parking in the onboarding process of the University. If you are interested in onboarding your university with the Spot Parking System, contact info@spotparking.app."
            },
            {
                question: "Is the app for students?",
                answer: "No, currently the app is for parking enforcement employees. A student app is on the way!"
            },
            {
                question: "How accurate is the object detection software?",
                answer: "Currently, the Spot Parking detection system has a 97% accuracy rate. This number is only increasing as the Spot Parking's AI continues to improve."
            },
            {
                question: "How does the system detect if my car has a parking pass?",
                answer: "At the entrance of each parking lot there is a license plate reader camera that scans vehicle's plate as it enters the  lot. The license plate number is then checked in the school database to determine if there is a parking pass associated with the vehicle."
            }
        ];

        const caret = activeDropdown === null ? <FaCaretRight /> : <FaCaretDown />;

        return (
            <div className="cu-faq-section mt-0 mb-4">
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className={`cu-faq-item ${activeDropdown === index ? "active" : ""}`}
                        onClick={() => toggleDropdown(index)}
                    >
                        <div className="cu-faq-question-container">
                            <div className="text-xl mt-1">{activeDropdown === index ? <FaCaretDown /> : <FaCaretRight />}</div>
                            <p className="cu-faq-question rubik-medium">{faq.question}</p>
                        </div>
                        {activeDropdown === index && <p className="cu-faq-answer rubik-light">{faq.answer}</p>}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="cu bg-lightYellow font-rubik">
            <Helmet>
                <title>Spot Parking | Contact Us</title>
                <meta name="description" content="Need assistance with Spot Parking? Reach out to us via our easy-to-use contact form or explore our comprehensive FAQ section. We're ready to help with your parking queries." />
                <link rel="canonical" href="https://spotparking.app/contact-us" />
            </Helmet>
            <div className="flex flex-col w-screen justify-center items-center h-fit min-h-screen pb-12 gray-gradient"
                style={{
                    borderBottomLeftRadius: `${radius}px`,
                    borderBottomRightRadius: `${radius}px`,
                    width: `${width}%`
                }}>
                <div className="w-screen">
                    <h1 className="text-8xl font-bold text-center font-stretched text-white">Have More Questions?</h1>
                    <p className="text-center text-4xl mt-4 text-white">We’d love to hear from you.</p>
                </div>
                <form className="cu-input-container bg-transparent" onSubmit={handleSubmit}>
                    <input className="cu-input bg-gray-600 text-white" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                    <input className="cu-input bg-gray-600 text-white" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <input className="cu-input bg-gray-600 text-white" placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    <textarea className="cu-textarea bg-gray-600 text-white" placeholder="Message" value={message} onChange={(e) => setMessage(e.target.value)} />
                    <button className="cu-submit-button outline outline-2 outline-offset-2 outline-yellow-200 text-white gray-gradient hover:outline-none hover:yellow-gradient hover:text-spotGray">{submitMessage}</button>
                </form>
                <Arrow />
            </div>
            <div className="cu-sec2">
                <div className="cu-sec2-text-container">
                    <h1 className="w-full text-center text-6xl font-bold py-8">Frequently Asked Questions</h1>
                </div>
                <FAQSection />
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Footer bg={"#FFF9E1"} />
            </div>
            <Toaster textColor={"#323232"} />
        </div>
    )
}