import React, { useState, useEffect } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { FaArrowRight, FaQuoteRight, FaMessage } from "react-icons/fa6";
import { PiSecurityCameraDuotone } from "react-icons/pi";
import { FaClock, FaDollarSign } from "react-icons/fa";
import { FaSquareParking } from "react-icons/fa6";
import multiPhoneRender from "../../../../assets/images/multiPhoneRender.png";
import phoneRender from "../../../../assets/images/phoneRender.png";
import statRender from "../../../../assets/images/stats.png";
import promo from "../../../../assets/videos/promo.mp4";
import Tilt from 'react-parallax-tilt';
import SplitType from "split-type";
import { MFooter } from "../../footer/MFooter";
import "./mHome.css";

export const MHome = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const screenHeight = window.innerHeight;
    let ratio = 0;
    const [scrollPosition, setScrollPosition] = useState(0);
    const [automated, setAutomated] = useState(false);
    const handleScroll = () => {
        const position = window.pageYOffset;
        ratio = position / screenHeight;
        setScrollPosition(ratio);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },);

    setTimeout(() => {
        setAutomated(true);
    }, 1000);

    const radius = Math.round((scrollPosition * 70));
    const width = 100 - (scrollPosition * 10);
    const smallWidth = `${(scrollPosition * 7) + 72}%`;

    const feature = (icon, title, description, url, delay) => {
        return (
            <div className={`flex flex-col justify-between gap-2 p-2 rounded-xl shadow-md border transition-all duration-700 ${delay} ${scrollPosition > 0.5 ? `opacity-100` : "opacity-0"}`}>
                <div className="flex w-full justify-start h-12">
                    <div className="w-12 h-12 rounded-lg shadow-md yellow-gradient fcc text-2xl text-spotGray">
                        {icon}
                    </div>
                </div>
                <h3 className="text-lg font-bold mb-2">{title}</h3>
                <p className="m-feature-description">{description}</p>
                <Link to={url} className="frc w-full justify-end">Learn More <FaArrowRight className="text-spotGray ml-1" /></Link>
            </div>
        )
    }

    const testimonialBlock = (university, quote, date, delay) => {
        return (
            <div className={`m-testimonial-block transition-all duration-700 font-rubik ${delay} ${scrollPosition > 2.2 ? `opacity-100` : "opacity-0"}`}>
                <Tilt className="m-quote-container" scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={10}>
                    <FaMessage className="m-message-icon" />
                    <FaQuoteRight className="m-quote-icon" />
                </Tilt>
                <div className="m-testimonial">
                    <p className="m-quote">"{quote}"</p>
                    <p className="m-university">- {university}, {date}</p>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (automated) {
            // Split the text into individual characters
            new SplitType('.practice', { types: 'chars' });
            let characters = document.querySelectorAll('.practice .char');

            // Apply a staggered animation to each character
            characters.forEach((char, index) => {
                setTimeout(() => {
                    char.style.opacity = '0';
                    char.style.transition = 'opacity 0.5s';
                    setTimeout(() => {
                        char.style.opacity = '1';
                        // char.style.textShadow = '0 0 1px #FFFFFF, 0 0 2px #FEF08A, 0 0 5px #FFFFFF, 0 0 10px #FEF08A';
                        char.style.background = 'linear-gradient(90deg, #FFE374, #FFF59D)';
                        char.style.backgroundClip = 'text';
                        char.style.webkitBackgroundClip = 'text';
                        char.style.color = 'transparent';
                        char.style.transform = 'translateY(-40px)';
                        char.style.transition = 'opacity 0.5s, transform 0.5s';
                    }, index * 50);
                }, index * 50);
            });
        }
    }, [automated]);

    return (
        <div className="m-home no-scrollbar w-screen overflow-clip font-rubik">
            <div className={`flex flex-col h-screen w-screen self-center gray-gradient overflow-hidden`}
                style={{
                    borderBottomLeftRadius: `${radius}px`,
                    borderBottomRightRadius: `${radius}px`,
                    width: `${width}%`
                }}>
                <div className="flex flex-col">
                    <h1 className="font-bold text-5xl w-[80%] text-white mt-32 ml-4 mb-10">Parking Enforcement</h1>
                    <h1 id="text" className={`practice w-auto ml-4 text-transparent transition-all text-5xl font-bold`}>Automated</h1>
                </div>
                <Link to="/features" className="w-fit h-fit"><button className="yellow-gradient rounded-md h-fit w-fit px-4 py-2 ml-4 font-bold text-2xl">Learn More</button></Link>
                <img src={multiPhoneRender} alt="phoneRender" className=" mt-auto" />
            </div>
            <div className="flex flex-col h-fit p-4 font-rubik">
                <div className={`grid w-full grid-cols-2 gap-[10px]`}>
                    {featureList.map((currentFeature, index) => (
                        feature(currentFeature.icon, currentFeature.title, currentFeature.description, currentFeature.url, currentFeature.delay)
                    ))}
                </div>
                <video
                    className="w-[90%] rounded-xl m-4 font-rubik"
                    src={promo}
                    controls
                    muted
                    loop>
                </video>
            </div>
            <div className={`flex-col-center max-w-[95%] bg-gray-700 text-white rounded-t-3xl`} style={{ width: smallWidth }}>
                <div className={`flex flex-col justify-center items-center py-8 mb-0 transition-all duration-700 ${scrollPosition > length ? `opacity-100` : "opacity-0"}`}>
                    <h1 className="m-show-off-title w-fit text-4xl text-white text-center">Real Time Analytics</h1>
                    <img src={statRender} alt="showOffImage" className="w-full h-full object-contain fcc" />
                    <Link to={"/features#section4"}><button className="w-fit px-2 py-1 rounded-md shadow-md yellow-gradient text-spotGray font-bold font-rubik">See More</button></Link>
                </div>
            </div>
            <div className={`flex-col-center max-w-[95%] bg-gray-700 text-white rounded-b-3xl mb-4`} style={{ width: smallWidth }}>
                <div className={`flex flex-col justify-center items-center py-8 mb-0 gap-4 transition-all duration-700 ${scrollPosition > length ? `opacity-100` : "opacity-0"}`}>
                    <h1 className="m-show-off-title w-fit text-4xl text-white text-center">Live Interactive Map</h1>
                    <img src={phoneRender} alt="showOffImage" className="m-show-off-image w-[90%]" />
                    <Link to={"/features#section3"}><button className="w-fit px-2 py-1 rounded-md shadow-md yellow-gradient text-spotGray font-bold font-rubik">Try it out!</button></Link>
                </div>
            </div>
            <h1 className={`m-testmonial-title text-4xl mb-1 transition-all duration-700 ${scrollPosition > 2.0 ? `opacity-100` : "opacity-0"}`}>University Testimonials</h1>
            <h1 className={`m-testmonial-subtitle transition-all duration-700 ${scrollPosition > 2.1 ? `opacity-100` : "opacity-0"}`}>When asked about parking enforcement</h1>
            {testimonials.map((currentTestimonial, index) => (
                testimonialBlock(currentTestimonial.university, currentTestimonial.quote, currentTestimonial.date, currentTestimonial.delay)
            ))}
            <div className=" p-4">
                <MFooter textColor={"#323232"} />
            </div>
        </div>
    )
}

const featureList = [
    {
        icon: <FaClock />,
        title: "Cost Efficient",
        description: "On average, Universities pay $400,000 on parking enforcement. With the Spot Parking system costs can be reduced by upwards of 65%. ",
        url: "/features#section4",
        delay: "delay-100"
    },
    {
        icon: <FaSquareParking />,
        title: "More Open Spaces",
        description: "By more efficiently enforcing parking polices, Spot Parking estimates opening up 21% more parking spaces for those who actually purchased a parking pass.",
        url: "/features#section4",
        delay: "delay-200"
    },
    {
        icon: <FaDollarSign />,
        title: "Increased Revenue",
        description: "51% of campus parking violations go undetected. The Spot Parking system detects these missed violations, capturing hundreds of thousands of dollars.",
        url: "/features#section4",
        delay: "delay-300"
    },
    {
        icon: <PiSecurityCameraDuotone />,
        title: "Improved Security",
        description: "Spot Parking's system boosts campus security by monitoring parking areas in real-time, promoting a safer environment.",
        url: "/features#section4",
        delay: "delay-500"
    }
]

const testimonials = [
    {
        university: "Westmont University",
        quote: "If we had a system that could automatically track people’s license plates and tell us if they need to be ticketed… That would make our life so much easier.",
        date: "January 2024",
        entrance: "delay-[200ms]"
    },
    {
        university: "Clackamas Community College",
        quote: "If we could have a camera system that tracked the cars and where they went… Man, that would be cool",
        date: "March 2024",
        entrance: "delay-[300ms]"
    },
    {
        university: "Virginia Commonwealth University",
        quote: "If I had to guess, I would say that only about 25% of people parked illegally end up getting ticketed.",
        date: "May 2024",
        entrance: "delay-[400ms]"
    }
]