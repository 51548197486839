import React, { useEffect, useMemo, useState } from "react";
import Arrow from "../desktop/Arrow";
import SplitType from "split-type";
import { Toaster, toast } from 'sonner';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import "../desktop/pages/home/home.css";
import { SparklesCore } from "../desktop/sparkles.tsx";
import { FaCheck } from "react-icons/fa";

const SecOne = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [automated, setAutomated] = useState(false);
    const [email, setEmail] = useState("");
    const [submitMessage, setSubmitMessage] = useState("Learn More");
    const screenHeight = window.innerHeight;
    let ratio = 0;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },);

    setTimeout(() => {
        setAutomated(true);
    }, 500);

    const handleScroll = () => {
        const position = window.pageYOffset;
        ratio = position / screenHeight;
        setScrollPosition(ratio);
    };

    const radius = Math.round((scrollPosition * 120));
    const width = 100 - (scrollPosition * 10);

    useEffect(() => {
        if (automated) {
            // Split the text into individual characters
            new SplitType('.startHere', { types: 'chars' });
            let characters = document.querySelectorAll('.startHere .char');

            // Apply a staggered animation to each character
            characters.forEach((char, index) => {
                setTimeout(() => {
                    char.style.opacity = '0';
                    char.style.transition = 'opacity 0.5s';
                    setTimeout(() => {
                        char.style.opacity = '1';
                        char.style.backgroundImage = 'linear-gradient(to right, #FFE374, #FEF08A)';
                        char.style.backgroundClip = 'text';
                        char.style.webkitBackgroundClip = 'text';
                        char.style.color = 'transparent';
                        char.style.transform = 'translateX(0px)';
                        char.style.transition = 'opacity 0.5s, transform 0.5s';
                    }, index * 50);
                }, index * 50);
            });
        }
    }, [automated]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            toast.error("Please enter your email below.");
            return;
        }

        // Reset form fields
        setEmail("");
        setSubmitMessage(<FaCheck className="text-2xl" />);

        const toastId = toast.loading("Signing you up for the latest tech...");
        const API_KEY = process.env.REACT_APP_API_KEY;

        try {
            const response = await fetch('https://1u5ksut8he.execute-api.us-east-1.amazonaws.com/default/HubSpotIntegration', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': API_KEY
                },
                body: JSON.stringify({
                    email: email,
                }),
            });

            if (response.ok) {
                toast.success("Good to go!", { id: toastId });
                setSubmitMessage("Learn More");
                console.log('Contact information submitted successfully!');
            } else {
                toast.error("Something went wrong.", { id: toastId });
                setSubmitMessage("Learn More");
                console.log('Failed to submit contact information.');
            }
        } catch (error) {
            toast.error("Something went wrong.", { id: toastId });
            setSubmitMessage("Learn More");
            console.error('Error:', error);
            console.log('An error occurred while submitting the information.');
        }
    };

    return (
        <div className="flex justify-center align-middle flex-col h-screen w-screen relative bg-lightYellow">
            <div className={`flex flex-col justify-center items-center h-screen w-screen self-center gray-gradient overflow-hidden`}
                style={{
                    borderBottomLeftRadius: `${radius}px`,
                    borderBottomRightRadius: `${radius}px`,
                    width: `${width}%`
                }}>
                {useMemo(() => <SparklesCore className="w-screen h-screen absolute" particleDensity={12} speed={1} background="transparent" minSize={1} maxSize={3} />, [])}
                <div className=" absolute top-0 left-0 w-screen h-screen bg-lightYellow -z-10"></div>
                <div className="z-10 fcc w-fit text-left">
                    <h1 className={`text-white h-fit text-7xl 2xl:text-8xl font-stretched font-bold transition-all`}>Automated Parking</h1>
                    <h1 className={`text-white h-fit text-7xl 2xl:text-8xl font-stretched font-bold transition-all`}>Enforcement
                        <span id="startHere" className={`startHere transition-all ml-[35px] inline-block text-transparent bg-clip-text text-7xl 2xl:text-8xl font-stretched font-bold`}>Starts Here</span>
                    </h1>
                    <h1 className="text-5xl mt-4 font-medium text-white">Discover how Spot Parking can transform your campus parking.</h1>
                    <div className="flex flex-row justify-between w-fit items-center mt-8">
                        <div className="fr">
                            <input className="flex flex-col items-center justify-center w-96 h-16 text-2xl pl-2 border-t-2 border-b-2 border-l-2 border-gray-500 rounded-l-xl bg-gray-600 z-10 text-white" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            <button onClick={handleSubmit} className=" bg-spotYellow w-48 h-16 rounded-r-xl text-spotGray font-bold text-2xl yellow-gradient transition-all duration-200 hover:shadow-lg hover:shadow-gray-500">{submitMessage}</button>
                        </div>
                        <div className="w-0 h-16 border-l-2 border-gray-500 mx-6"></div>
                        <div className="flex flex-row justify-center align-middle items-center cursor-pointer w-fit p-4 bg-transparent border-2 rounded-xl border-spotYellow text-white transition-all duration-200 font-bold text-2xl hover:yellow-gradient hover:text-spotGray">Request a Demo <MdOutlineKeyboardArrowRight /></div>
                    </div>
                </div>
                <Arrow />
            </div>
            <Toaster richColors textColor={"#323232"} />
        </div>
    )
}

export default SecOne;