import React, { useEffect, useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import { Footer } from "../../footer/Footer";
import { PiSecurityCameraDuotone } from "react-icons/pi";
import { FaClock, FaDollarSign } from "react-icons/fa";
import { FaSquareParking, FaArrowRight, FaMessage, FaQuoteRight } from "react-icons/fa6";
import Arrow from "../../Arrow";
import multiPhoneRender from "../../../../assets/images/multiPhoneRender.png";
import phoneRender from "../../../../assets/images/phoneRender.png";
import statRender from "../../../../assets/images/stats.png";
import promo from "../../../../assets/videos/promo.mp4";
// import background from "../../../../assets/images/background.png";
import "./home.css";
import SplitType from "split-type";
import { Helmet } from "react-helmet-async";

export const Home = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [automated, setAutomated] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);

        const handleScroll = () => {
            setScrollPosition(window.pageYOffset / window.innerHeight);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    setTimeout(() => {
        setAutomated(true);
    }, 500);

    const feature = (icon, title, description, url, entrance) => {
        return (
            <div className={`opacity-0 mt-16 transition-all ${scrollPosition > 0.4 ? `opacity-100 mt-0 duration-700 ${entrance}` : null}`}>
                <div className="feature-icon-container text-spotGray text-3xl mb-6 group hover:scale-105 transition-all duration-300 ease-in-out">
                    <div className="group-hover:scale-x-[-1] transition-all duration-300 ease-in-out">
                        {icon}
                    </div>
                </div>
                <h3 className="font-bold font-rubik">{title}</h3>
                <p className="feature-description rubik-light">{description}</p>
                <div className="flex flex-row">
                    <Link to={url} className="feature-link">Learn More</Link>
                    <FaArrowRight className="feature-arrow self-center" />
                </div>
            </div>
        )
    }

    const featureList = [
        {
            icon: <FaClock />,
            title: "Cost Efficient",
            description: "On average, Universities pay $400,000 on parking enforcement. With the Spot Parking system costs can be reduced by upwards of 65%! ",
            url: "/features#section4",
            entrance: "delay-[200ms]"
        },
        {
            icon: <FaSquareParking />,
            title: "More Open Spaces",
            description: "By more efficiently enforcing parking polices, Spot Parking estimates opening up to 27% more parking spaces for those who actually purchased a parking pass.",
            url: "/features#section4",
            entrance: "delay-[300ms]"
        },
        {
            icon: <FaDollarSign />,
            title: "Increased Revenue",
            description: "On average, 51% of parking violations go undetected. The Spot Parking system will detect theses missed violations, capturing hundreds of thousands of dollars.",
            url: "/features#section4",
            entrance: "delay-[400ms]"
        },
        {
            icon: <PiSecurityCameraDuotone />,
            title: "Improved Security",
            description: "Spot Parking's system boosts campus security by swiftly detecting violations and monitoring parking areas in real-time, promoting a safer environment.",
            url: "/features#section4",
            entrance: "delay-[500ms]"
        }
    ]

    const showOffSection = (title, image, buttonTitle, url) => {
        if (title === "Real Time Analytics") {
            return (
                <div className="show-off-section mt-8">
                    <h2 className="show-off-title text-white">{title}</h2>
                    <img src={image} alt="showOffImage" className="w-full h-full fcc object-contain" />
                    <Link to={url}><button className="show-off-button mt-4">{buttonTitle}</button></Link>
                </div>
            )
        } else {
            return (
                <div className="show-off-section mt-8">
                    <h2 className="show-off-title text-white">{title}</h2>
                    <img src={image} alt="showOffImage" className="show-off-image" />
                    <Link to={url}><button className="show-off-button">{buttonTitle}</button></Link>
                </div>
            )
        }
    }

    const testimonials = [
        {
            university: "Westmont University",
            quote: "If we had a system that could automatically track people’s license plates and tell us if they need to be ticketed… That would make our life so much easier.",
            date: "January 2024",
            entrance: "delay-[200ms]"
        },
        {
            university: "Clackamas Community College",
            quote: "If we could have a camera system that tracked the cars and where they went… Man, that would be cool",
            date: "March 2024",
            entrance: "delay-[300ms]"
        },
        {
            university: "Virginia Commonwealth University",
            quote: "If I had to guess, I would say that only about 25% of people parked illegally end up getting ticketed.",
            date: "May 2024",
            entrance: "delay-[400ms]"
        }
    ]

    const testimonialBlock = (university, quote, date, entrance) => {
        return (
            <div className={`testimonial-block rubik-light h-24 flex flex-row opacity-0 mt-6 transition-all ${scrollPosition > 1.3 ? `opacity-100 mt-0 duration-700 ${entrance}` : null}`}>
                <div className="quote-container flex" scale={1.1} tiltMaxAngleX={10} tiltMaxAngleY={10}>
                    <FaMessage className="message-icon" />
                    <FaQuoteRight className="quote-icon" />
                </div>
                <div className="ml-16 my-auto">
                    <p className="quote">"{quote}"</p>
                    <p className="university">- {university}, {date}</p>
                </div>
            </div>
        )
    }

    const radius = Math.round((scrollPosition * 120));
    const width = 100 - (scrollPosition * 10);
    const smallWidth = (scrollPosition * 8) + 70;

    useEffect(() => {
        if (automated) {
            // Split the text into individual characters
            new SplitType('.practice', { types: 'chars' });
            let characters = document.querySelectorAll('.practice .char');

            // Apply a staggered animation to each character
            characters.forEach((char, index) => {
                setTimeout(() => {
                    char.style.opacity = '0';
                    char.style.transition = 'opacity 0.5s';
                    setTimeout(() => {
                        char.style.opacity = '1';
                        char.style.backgroundImage = 'linear-gradient(to right, #FFE374, #FEF08A)';
                        char.style.backgroundClip = 'text';
                        char.style.webkitBackgroundClip = 'text';
                        char.style.color = 'transparent';
                        char.style.transform = 'translateY(-40px)';
                        char.style.transition = 'opacity 0.5s, transform 0.5s';
                    }, index * 50);
                }, index * 50);
            });
        }
    }, [automated]);

    return (
        <div className="flex justify-center align-middle flex-col h-auto w-screen overflow-clip bg-lightYellow font-rubik">
            <Helmet>
                <title>Spot Parking | Home</title>
                <meta name="description" content="Spot Parking offers efficient, automated parking enforcement to universities, increasing revenue and freeing up to 27% more parking spaces." />
                <link rel="canonical" href="https://spotparking.app" />
            </Helmet>
            <div className={`flex h-screen w-screen self-center gray-gradient overflow-hidden`}
                style={{
                    borderBottomLeftRadius: `${radius}px`,
                    borderBottomRightRadius: `${radius}px`,
                    width: `${width}%`
                }}>
                <div className="w-screen h-screen bg-lightYellow -z-10"></div>
                <div className="z-10">
                    <h1 className={`absolute w-96 text-white text-8xl 2xl:text-9xl font-stretched font-bold top-32 transition-all left-36`}>Parking Enforcement
                        <span id="text" className={`practice absolute transition-all inline-block w-[900px] text-transparent bg-clip-text text-8xl 2xl:text-9xl font-stretched font-bold top-[235px] 2xl:top-[300px]`}>Automated</span></h1>
                </div>
                <Link to="/features"><button className="learn-more-button top-[450px] 2xl:top-[600px] yellow-gradient">Learn More</button></Link>
                <img src={multiPhoneRender} alt="phoneRender" className="w-[700px] h-[650px] mt-auto" />
                <Arrow />
            </div>
            <div className="flex flex-row justify-center p-4 h-[580px] w-screen bg-lightYellow">
                <div className="grid w-[45%] grid-cols-2 grid-rows-2 gap-[10px] ml-4">
                    {featureList.map((currentFeature, index) => (
                        feature(currentFeature.icon, currentFeature.title, currentFeature.description, currentFeature.url, currentFeature.entrance)
                    ))}
                </div>
                <div className="flex flex-col justify-center">
                    <video
                        className="max-w-[800px] min-x-[800px] rounded-2xl transition-all duration-500 ease-in-out z-20 peer hover:mb-8 mr-4"
                        src={promo}
                        controls
                        autoPlay
                        muted
                        loop
                        preload="auto">
                    </video>
                    <div className="absolute flex w-[760px] h-24 ml-5 mt-[350px] mx-auto bg-gray-600 rounded-3xl transition-all duration-500 z-10 peer-hover:mt-[360px]"></div>
                    <div className="absolute flex w-[700px] h-24 ml-12 mt-[350px] mx-auto bg-gray-400 rounded-3xl transition-all duration-500 z-0 peer-hover:mt-[400px]"></div>
                </div>
            </div>
            <div className="flex flex-row h-fit p-4 mt-20 z-20 bg-gray-700 m-auto rounded-t-3xl up-lg" style={{ width: `${smallWidth}%` }}>
                {showOffSection("Real Time Analytics", statRender, "See More", "/features#section4")}
                {showOffSection("Live Interactive Map", phoneRender, "Try it out!", "/features#section3")}
            </div>
            <div className="home-sec4 relative">
                <div className="m-auto h-16 bg-gray-700 rounded-b-3xl z-40 mb-12 shadow-2xl" style={{ width: `${smallWidth}%` }}></div>
                <h2 className={`testmonial-title opacity-0 transition-all ${scrollPosition > 1.2 ? `opacity-100 mt-0 duration-700 delay-100` : null}`}>University First Impressions</h2>
                <h3 className={`testmonial-subtitle mt-2 opacity-0 transition-all ${scrollPosition > 1.2 ? `opacity-100 mt-0 duration-700 delay-200` : null}`}>When asked about parking enforcement</h3>
                {testimonials.map((currentTestimonial, index) => (
                    testimonialBlock(currentTestimonial.university, currentTestimonial.quote, currentTestimonial.date, currentTestimonial.entrance)
                ))}
            </div>
            <div className="flex-col-center w-screen">
                <Footer textColor={"#323232"} />
            </div>
        </div>
    )
}