// This page is just for the map component. It pulls data from MapBox (open source map API) and displays the map on the screen.
// Each polygon is a parking spot. The color of the polygon is determined by the violation type.
// The map is interactive and can be zoomed in and out of.
// The map has default settings that can be changed by passing in props such as zoom and coordinates.

import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import "./Map.css";
import { FaMinus, FaPlus } from "react-icons/fa6";
import ViolationCard from "./violationCard/ViolationCard";

// Mapbox Tokens
mapboxgl.accessToken = "pk.eyJ1IjoicnlhbmhhZ2VydHkzMiIsImEiOiJjbHYyd25vdGEwbXhoMmtwN3hkZGZ6ZmxmIn0.8j4S80-LC8wX7mLHwWrc_g";

// Map Creation
const Map = ({
    startSpot,
    styling,
    itemClickable,
    highlight,
    lot
}) => {
    //const navigate = useNavigate();
    const [isMapReady, setIsMapReady] = useState(false);
    const [map, setMap] = useState(null);
    const uniCoordinates = [-111.64843, 40.25116];

    // Default settings
    const startSpotCoordinates = startSpot ? startSpot : uniCoordinates;
    const highlightedItem = highlight ? highlight : null;
    const itemClickableBool = itemClickable === undefined ? true : itemClickable;
    const baseZoom = 18; // Line Size and base zoom have an inverse relationship
    const baseWidth = 1;
    const lotNumber = lot ? lot : null;
    const colorScheme = "mapbox://styles/mapbox/light-v10"
    const mapCss = styling ? styling : {
        top: "0px",
        right: "0px",
        touchAction: "none",
        width: "100vw",
        height: "100vh",
    };

    // When clicked, naviagate to the violation page of that violation
    const [selectedViolation, setSelectedViolation] = useState(null);
    const handleItemClick = ({ LicensePlate, Spot, TimeSinceParked, Distance, VehicleType, PermitNumber, ViolationType, Coordinates, Lot }) => {
        setSelectedViolation({ LicensePlate, Spot, TimeSinceParked, Distance, VehicleType, PermitNumber, ViolationType, Coordinates, Lot });
    };

    const handleCloseViolationCard = () => {
        //const violationColor = selectedViolation.ViolationType === "N/A" ? "#aad7a4" : "#D1807D";
        //map.setPaintProperty(`polygon-layer-${selectedViolation.LicensePlate}`, "fill-color", violationColor); // Change fill-color back to original
        setSelectedViolation(null);
    };

    // Map Object
    useEffect(() => {
        const newMap = new mapboxgl.Map({
            container: "map-container",
            style: colorScheme,
            center: startSpotCoordinates,
            zoom: 17.6,
            attributionControl: false,
            scrollZoom: false
        });

        // Create map
        setMap(newMap);
        setIsMapReady(true);

        // Add features
        newMap.on("load", async () => {
            await addAllPolygons(newMap);
            addLines(newMap);
        });

        // Disable pointer cursor
        newMap.getCanvas().style.cursor = "default";

        // Add line cursor
        newMap.on("mousemove", (e) => {
            const features = newMap.queryRenderedFeatures(e.point);
            const polygonFeatures = features.filter((feature) => feature.layer.type === "fill");
            newMap.getCanvas().style.cursor = polygonFeatures.length ? "pointer" : "default";
        });

        return () => {
            newMap.remove();
        };
        // eslint-disable-next-line
    }, []);

    const addAllPolygons = async (map) => {
        const listData = require('./violationData.json');
        const coordinatesArray = listData.map((item) => [
            JSON.parse(item.Coordinates),
            item.Distance,
            item.LicensePlate,
            item.Lot,
            item.PermitNumber,
            item.Spot,
            item.TicketWritten,
            item.TimeSinceParked,
            item.VehicleType,
            item.ViolationType
        ]);

        // Filter by lot number
        const newArray = lotNumber != null
            ? coordinatesArray.filter(item => item[3] == lotNumber)
            : coordinatesArray;

        // Build the FeatureCollection
        const features = newArray.map(([Coordinates, Distance, LicensePlate, Lot, PermitNumber, Spot, TicketWritten, TimeSinceParked, VehicleType, ViolationType], index) => {
            let fillColor;
            if (TicketWritten === true) {
                fillColor = "#9C9C9C";
            } else if (ViolationType === "N/A") {
                fillColor = "#aad7a4";
            } else {
                fillColor = "#D1807D";
            }

            const randomTime = Math.floor(Math.random() * (24 * 60)) + 1; // Generate random time in minutes between 1 and 24 hours

            const hours = Math.floor(randomTime / 60);
            const minutes = randomTime % 60;
            const randomTimeFormatted = `${hours}hr ${minutes}min`;

            return {
                type: "Feature",
                geometry: {
                    type: "Polygon",
                    coordinates: [Coordinates]
                },
                properties: {
                    id: `polygon-layer-${LicensePlate}`,
                    ViolationType: ViolationType,
                    LicensePlate: LicensePlate,
                    Spot: Spot,
                    Lot: Lot,
                    TimeSinceParked: randomTimeFormatted,
                    Distance: Distance,
                    VehicleType: VehicleType,
                    PermitNumber: PermitNumber,
                    Coordinates: Coordinates,
                    TicketWritten: TicketWritten,
                    fillColor: fillColor
                }
            };
        });

        const featureCollection = {
            type: "FeatureCollection",
            features: features
        };

        // Add the FeatureCollection to the map
        map.addSource('polygons', {
            type: 'geojson',
            data: featureCollection
        });

        map.addLayer({
            id: 'polygon-layer',
            type: 'fill',
            source: 'polygons',
            paint: {
                'fill-color': ['get', 'fillColor'],
                'fill-opacity': 1,
                'fill-outline-color': 'transparent',
            }
        });

        // Click handling
        if (itemClickableBool === true) {
            map.on('click', 'polygon-layer', (e) => {
                const clickedFeature = e.features[0];
                const { LicensePlate, Spot, TimeSinceParked, Distance, VehicleType, PermitNumber, ViolationType, Coordinates, Lot } = clickedFeature.properties;
                const newColor = ViolationType === "N/A" ? "#4C9A2A" : "#800020";

                // Update URL search params
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.set("fromMap", "true");

                // Handle item click
                handleItemClick({ LicensePlate, Spot, TimeSinceParked, Distance, VehicleType, PermitNumber, ViolationType, Coordinates, Lot });

                // Change fill-color to new color
                map.setFeatureState({
                    source: 'polygons',
                    id: clickedFeature.id
                }, {
                    fillColor: newColor
                });
            });
        }
    };

    function jsonBuilder(arr) {
        /** 
        * Description: Takes in a 3d array of coordinates and converts it to a geojson format
        * Args:
        *     arr: 3d array of coordinates
        * Returns:
        *     finalJson: 3d array of coordinates in the format of a geojson
        */

        var finalJson = []

        arr.forEach((coordinates) => {
            finalJson.push({
                "type": "Feature",
                "properties": {},
                "geometry": {
                    "coordinates": coordinates,
                    "type": "LineString"
                }
            })
        })

        return finalJson
    }

    // Snag line data from the session storage
    const cordInput = require('./lineData.json');

    //Add lines to the map. Gets its data from the jsonBuilder function 
    const addLines = async (map) => {
        map.addSource('route', {
            'type': 'geojson',
            'data': {
                "type": "FeatureCollection",
                "features": jsonBuilder(cordInput)
            }
        });

        map.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
                'line-join': 'round',
                'line-cap': 'square',
                'line-sort-key': 10
            },
            paint: {
                'line-color': '#ffe374',
                "line-width": {
                    "type": "exponential",
                    "base": 2,
                    "stops": [
                        [0, baseWidth * Math.pow(2, (0 - baseZoom))],
                        [24, baseWidth * Math.pow(2, (24 - baseZoom))]
                    ]
                }
            },
            minzoom: 17
        });
    };

    // Zoom Controls
    const handleZoomIn = () => {
        map.zoomIn();
    };

    const handleZoomOut = () => {
        map.zoomOut();
    };

    const ZoomControls = () => {
        return (
            <div className="absolute bottom-20 left-4 flex flex-col gap-2 text-2xl text-spotGray">
                <button className="w-16 h-16 justify-center flex items-center rounded-xl bg-gray-700/20 backdrop-blur-sm hover:bg-gray-700/30" onClick={handleZoomIn}>
                    <FaPlus />
                </button>
                <button className="w-16 h-16 justify-center flex items-center rounded-xl bg-gray-700/20 backdrop-blur-sm hover:bg-gray-700/30" onClick={handleZoomOut}>
                    <FaMinus />
                </button>
            </div>
        );
    };

    return (
        <div id="map-container" style={mapCss} className="relative">
            {/* <RefreshButton darkMode={darkMode} onClick={() => map.resize()} /> */}
            {!isMapReady && (
                <div className="loading-container">
                    <div className="loading-spinner"></div>
                </div>
            )}
            <ZoomControls />
            {selectedViolation && (
                <div className=" z-50">
                    <ViolationCard violation={selectedViolation} onClose={handleCloseViolationCard} />
                </div>
            )}
        </div>
    );
};

export default Map;