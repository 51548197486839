import React, { useState, useEffect } from "react";
import { Footer } from "../../footer/Footer";
import Tilt from 'react-parallax-tilt';
import x from "../../../../assets/images/x.png";
import linkedIn from "../../../../assets/images/linkedIn.png";
import instagram from "../../../../assets/images/instagram.png";
import sioy from "../../../../assets/images/sioy.jpg";
import uec from "../../../../assets/images/uec.jpg";
import ihub from "../../../../assets/images/ihub.jpg";
import bereal from "../../../../assets/images/bereal.jpg";
import byuLogo from "../../../../assets/images/byuLogo.jpg";
import dean from "../../../../assets/images/dean.png";
import ryan from "../../../../assets/images/ryan.png";
import cooper from "../../../../assets/images/cooper.png";
import { FiExternalLink } from "react-icons/fi";
import lines from "../../../../assets/lines.svg";
import Arrow from "../../Arrow";
import { Helmet } from "react-helmet-async";
import "./about.css";
import "../../../../assets/ReactParallaxTilt.css";

export const About = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);

        const handleScroll = () => {
            setScrollPosition(window.pageYOffset / window.innerHeight);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const width = 100 - (scrollPosition * 5);
    const borderRadius = Math.round((scrollPosition * 120));

    const line = (width, height, point, rotate) => {
        const left = point.x;
        const top = point.y;

        const lineStyle = {
            width: width,
            height: height,
            left: left,
            top: top,
            position: 'absolute',
            transform: rotate,
            transformOrigin: '0 0',
            background: 'var(--yellow)',
            border: '4.29px var(--yellow) solid'
        }

        return (
            <div style={lineStyle}></div>
        )
    }

    const Dot = (width, point, event) => {
        const left = point.x;
        const top = point.y;
        const [showOverlay, setShowOverlay] = useState(false);
        const dotStyle = {
            width: width,
            height: width,
            left: left,
            top: top,
            position: 'absolute',
            background: 'var(--yellow)',
            borderRadius: 9999,
            zIndex: 1,
        }

        const overlayStyle = {
            top: top - 130,
            left: left - 130,
        }

        const handleMouseEnter = () => {
            setShowOverlay(true);
        }

        const handleMouseLeave = () => {
            setShowOverlay(false);
        }

        const overlay = (event) => {
            const itemList = {
                "BeReal": {
                    image: bereal,
                    text: "September 1st, 2023 Spot Parking became a registered LLC in the state of Utah at Cooper, Ryan and Dean’s first in-person meeting after serving 2 year missions for their church."
                },
                "BYU": {
                    image: byuLogo,
                    text: "Over Near Year's break including from 10PM-1AM on New Years Eve, the team created a presentation that was later given to memebrs of BYU's security team. This was the beginning of Spot's relationship with BYU."
                },
                "SIOY": {
                    image: sioy,
                    text: "Spot Parking competed in the Student Innovator of the Year competition at Brigham Young University. Taking on 54 other businesses they were victorious, winning $12,000!",
                    learnMoreLink: "https://news.byu.edu/intellect/innovative-group-of-byu-students-roll-out-new-ai-tech-to-solve-parking-problems"
                },
                "iHUB": {
                    image: ihub,
                    text: "Becoming the first business to be admitted into iHUB (The Innovation Hub of Utah), the entrepreneurship incubator in Utah county accelerated Spot Parking’s growth and expansion.",
                    learnMoreLink: "https://www.ihubutah.org/"
                },
                "UEC": {
                    image: uec,
                    text: "At the UEC (Utah Entrepreneurship Challenge), 20 businesses from 10 different universities across the state of Utah competed by presenting their startups to a panel of judges. Spot Parking was selected to represent BYU and took 3rd winning $8,000!",
                    learnMoreLink: "https://lassonde.utah.edu/powder-baby-dry-shampoo-wins-30000-grand-prize-at-2024-utah-entrepreneur-challenge/#attachment_39695"
                },
            }

            for (let i in itemList) {
                return (
                    <div style={{ zIndex: "50000" }} className="dot-overlay-container">
                        <img src={itemList[event].image} className="overlay-image" alt="logo" />
                        <p className="overlay-text rubik-normal">{itemList[event].text}</p>
                        {itemList[event].learnMoreLink ?
                            <a href={itemList[event].learnMoreLink} target="_blank" rel="noopener noreferrer" className="overlay-learn-more">
                                Learn More
                            </a>
                            : null}
                    </div>
                )
            }
        }

        return (
            <div>
                <div className="
                absolute 
                bg-[var(--yellow)] 
                rounded-full 
                z-[1]
                animate-customPing
            "
                    style={{
                        width: `${width}px`,
                        height: `${width}px`,
                        left: `${left}px`,
                        top: `${top}px`,
                    }} onMouseEnter={handleMouseEnter}></div>
                <div style={dotStyle} onMouseEnter={handleMouseEnter}></div>
                <Tilt id="overlay" className={`overlay-container ${showOverlay === true ? 'active' : ''}`} style={overlayStyle} onLeave={handleMouseLeave}>
                    {overlay(event)}
                </Tilt>
            </div>
        )
    }

    const date = (point, month, date) => {
        const left = point.x;
        const top = point.y;
        const textStyle = {
            top: top,
            left: left,
        }

        return (
            <div className="date-text" style={textStyle}>{month}<br />{date}</div>
        )
    }

    const FounderCard = (name) => {
        const [showFounderOverlay, setShowFounderOverlay] = useState(0);
        const founderList = {
            "Dean Smith": {
                image: dean,
                text: "Dean is studying computer engineering at the University of Utah. As Spot Parking's CTO, he leverages his programming expertise to develop the company's software. Dean met Ryan during a two-year mission in Albuquerque, New Mexico.",
                xLink: "https://twitter.com/spotparking_",
                linkedInLink: "https://www.linkedin.com/in/dean-smith-508bab173/",
                instagramLink: "https://www.instagram.com/deanasmith607/"
            },
            "Ryan Hagerty": {
                image: ryan,
                text: "Ryan is pursuing a degree in Information Systems at Brigham Young University. Serving as Spot Parking’s CEO, he is passionate about using technology to address real-world problems. Ryan met Dean during their mission in Albuquerque, New Mexico.",
                xLink: "https://twitter.com/ryandhagerty",
                linkedInLink: "https://www.linkedin.com/in/ryandhagerty/",
                instagramLink: "https://www.instagram.com/ryan.hagerty/"
            },
            "Cooper Young": {
                image: cooper,
                text: "Cooper is studying Information Systems at Brigham Young University. As Spot Parking's COO, he manages operations with a keen organizational sense. Cooper grew up in Spokane, Washington, where he met Ryan, and served a mission in Oaxaca, Mexico.",
                xLink: "https://twitter.com/spotparking_",
                linkedInLink: "https://www.linkedin.com/in/cooperyoungbyu/",
                instagramLink: "https://www.instagram.com/cooper.eli.young/"
            },
        }

        const handleMouseEnter = () => {
            setShowFounderOverlay(1);
            console.log(showFounderOverlay);
        }

        const handleMouseLeave = () => {
            setShowFounderOverlay(0);
        }

        return (
            <div className="founder-card">
                <Tilt tiltReverse={true} tiltMaxAngleX={15} tiltMaxAngleY={15} scale={1.1} transitionSpeed={2000} onLeave={handleMouseLeave}>
                    <img src={founderList[name].image} alt="founder" className="founder-image" onMouseEnter={handleMouseEnter} />
                    <div className={`founder-overlay ${showFounderOverlay === 1 ? 'active' : ''}`}>
                        <p className="founder-text text-sm rubik-normal">{founderList[name].text}</p>
                    </div>
                </Tilt>
                <p className="founder-name">{name}</p>
                <div className="founder-links-container">
                    <a href={founderList[name].linkedInLink} className="logo-link transition-all duration-200 hover:scale-110" target="_blank" rel="noopener noreferrer">
                        <img src={linkedIn} alt={"LinkedIn"} className="founder-link-icon" />
                    </a>
                    <a href={founderList[name].instagramLink} className="logo-link transition-all duration-200 hover:scale-110" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt={"Instagram"} className="founder-link-icon" />
                    </a>
                    <a href={founderList[name].xLink} className="logo-link transition-all duration-200 hover:scale-110" target="_blank" rel="noopener noreferrer">
                        <img src={x} alt={"X"} className="founder-link-icon" />
                    </a>
                </div>
            </div>
        )
    }

    const calculateCenterPoint = () => {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const centerX = (screenWidth - 40) / 2;
        const centerY = screenHeight / 2 + 50;
        return { x: centerX, y: centerY };
    }

    const [centerPoint, setCenterPoint] = useState(calculateCenterPoint());

    useEffect(() => {
        const handleResize = () => {
            const newCenterPoint = calculateCenterPoint();
            setCenterPoint(newCenterPoint);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function mover(x, y) {
        return { x: centerPoint.x + x, y: centerPoint.y + y };
    }

    const xDiff = 195;
    const lineDiff = 190
    const dateDiff = 190;
    const radius = 100 - (scrollPosition * 10);
    const smallWidth = `${75 + (scrollPosition * 8)}%`;
    const mediumWidth = `${80 + (scrollPosition * 8)}%`;

    return (
        <div className="flex justify-center align-middle flex-col h-auto w-screen bg-lightYellow font-rubik">
            <Helmet>
                <title>Spot Parking | About Us</title>
                <meta name="description" content="Learn about the passionate team behind Spot Parking, their journey from winning competitions to building innovative solutions for campus parking and safety." />
                <link rel="canonical" href="https://spotparking.app/about-us" />
            </Helmet>
            <div className="flex flex-col h-screen w-screen self-center overflow-hidden gray-gradient items-center" style={{
                borderBottomLeftRadius: `${borderRadius}px`,
                borderBottomRightRadius: `${borderRadius}px`,
                width: `${width}%`
            }}>
                <div className="flex font-bold text-white mt-32 text-8xl text-center fixed">See the Journey</div>
                <div className="timeline-container">
                    {Dot(37.52, mover((xDiff * -2), -85), "BeReal")}
                    {Dot(37.52, mover((xDiff * -1), 48), "BYU")}
                    {Dot(37.52, mover((xDiff * 0), -85), "SIOY")}
                    {Dot(37.52, mover((xDiff * 1), 48), "iHUB")}
                    {Dot(37.52, mover((xDiff * 2), -85), "UEC")}
                    {line(237.24, 0, mover((lineDiff * -2), -78), 'rotate(35.34deg)')}
                    {line(237.24, 0, mover((lineDiff * -1), 76), 'rotate(-36deg)')}
                    {line(237.24, 0, mover((lineDiff * 0 + 10), -78), 'rotate(35.34deg)')}
                    {line(237.24, 0, mover((lineDiff * 1 + 20), 74), 'rotate(-36deg)')}
                    {date(mover((dateDiff * -2 - 55), -160), "September", "1st 2023")}
                    {date(mover((dateDiff * -1 - 40), 105), "January", "9th 2024")}
                    {date(mover((dateDiff * 0 - 40), -160), "February", "29th 2024")}
                    {date(mover((dateDiff * 1 - 40), 105), "March", "12th 2024")}
                    {date(mover((dateDiff * 2 - 40), -160), "March", "23rd 2024")}
                </div>
                <div className="parking-lot-container" style={{
                    scale: `${radius}%`,
                    filter: `blur(${scrollPosition * 20}px)`
                }}>
                    <div className="lot-left">
                        <img src={lines} alt="lines" className="lines" />
                    </div>
                    <div className="lot-right">
                        <img src={lines} alt="lines" className="lines" />
                    </div>
                </div>
                <Arrow />
            </div>
            <div className="about-section2 z-20 pb-8">
                <div className={`founders-main-title text-right gray-gradient text-white rounded-r-3xl shadow-xl pr-12`} style={{ width: smallWidth }}>Meet the Founders</div>
                <div className={`founder-card-container mt-6 pt-20`} style={{ width: smallWidth }}>
                    {FounderCard("Dean Smith")}
                    {FounderCard("Ryan Hagerty")}
                    {FounderCard("Cooper Young")}
                </div>
                <div className={`flex flex-row justify-between items-center gray-gradient min-w-[1200px] mt-8 self-end rounded-l-3xl h-72 shadow-xl`} style={{ width: mediumWidth }}>
                    <div className="flex flex-col">
                        <div className="pl-12 w-auto text-7xl font-bold font-stretched text-left text-white leading-tight">Spot Parking gives back</div>
                        <div className="pl-12 w-[80%] text-7xl font-bold font-stretched text-left text-white leading-tight">to the community.</div>
                    </div>
                    <a href="https://ihubutah.org" target="_blank" rel="noopener noreferrer" className="w-48 h-16 mr-12 bg-spotYellow text-spotGray transition-all shadow-sm duration-200 rounded-xl flex justify-center items-center text-3xl font-bold hover:shadow-lg hover:text-spotGray z-20">See How <FiExternalLink className="ml-2 text-spotGray" /></a>
                </div>
            </div>
            <div>
                <Footer bg={"transparent"} textColor={"#323232"} />
            </div>
        </div>
    )
}