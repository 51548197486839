import React, { useState, useEffect } from 'react';
import { MHeader } from '../header/MHeader';
import { MFooter } from '../footer/MFooter';
import Arrow from '../../desktop/Arrow';
import { isNumber } from '@tsparticles/engine';

export const MCalculator = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        // window.scrollTo(0, 0);

        const handleScroll = () => {
            setScrollPosition(window.pageYOffset / window.innerHeight);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const width = 100 - (scrollPosition * 5);
    const borderRadius = Math.round((scrollPosition * 120));

    const [calcType, setCalcType] = useState(2);
    const [initLabor, setInitLabor] = useState("");
    const [initCitation, setInitCitation] = useState("");
    const [initPass, setInitPass] = useState("");
    const [spaces, setSpaces] = useState("");
    const [lots, setLots] = useState("");
    const [coverage, setCoverage] = useState("");
    const [paybackMonths, setPaybackMonths] = useState(0);
    const [data, setData] = useState([]);

    const calculate = () => {
        console.log(coverage)
        if (initLabor === "" || initCitation === "" || initPass === "" || spaces === "" || lots === "" || coverage === "") {
            alert("Please fill in all fields");
            return;
        }

        if (!Number.isInteger(initLabor) || !Number.isInteger(initCitation) || !Number.isInteger(initPass) || !Number.isInteger(spaces) || !Number.isInteger(lots) || !Number.isInteger(coverage)) {
            alert("Please enter only integers in the fields");
            return;
        }

        const caseInfo = {
            // [Worst Case, Best Case]
            labor: [0.4, 0.6, 0.75], // % decrease in labor costs
            citation: [0.4, 0.7, 1], // % increase in citations
            pass: [0.1, 0.2, 0.3], // % increase in pass revenue
            cameras: [50, 100, 150], // # of spaces per camera
            install: [1100, 850, 600] // Cost per camera to install
        }

        // Initial adjustments set from the calculation type
        const laborAdjustment = initLabor * caseInfo.labor[calcType];
        const citationAdjustment = caseInfo.citation[calcType];
        const passAdjustment = 1 + caseInfo.pass[calcType];
        const cameraAdjustment = caseInfo.cameras[calcType];
        const installAdjustment = caseInfo.install[calcType];
        const cameraPrice = 2000;

        // Calculate the revenue and expenses for each year
        const yearlyCitationRevenue = [initCitation, initCitation * (1 + citationAdjustment), initCitation * (1 + (citationAdjustment * 0.7)), initCitation * (1 + (citationAdjustment * 0.7)), initCitation * (1 + (citationAdjustment * 0.7)), initCitation * (1 + (citationAdjustment * 0.7))]; //^
        const yearlyPassRevenue = [initPass, initPass * passAdjustment, initPass * passAdjustment, initPass * passAdjustment, initPass * passAdjustment, initPass * passAdjustment]; //^
        const extraCapitalFromLabor = [0, laborAdjustment, laborAdjustment, laborAdjustment, laborAdjustment, laborAdjustment]; //^
        const totalRevenue = yearlyCitationRevenue.map((ycr, i) => ycr + yearlyPassRevenue[i] + extraCapitalFromLabor[i]); //^

        // Calculate the number of cameras needed and all data based on the cameras
        const prelimCams = Math.ceil(lots + ((spaces / cameraAdjustment * lots) * (1 - (parseInt(coverage.toString()) / 100))));
        const requiredCameras = prelimCams < (lots * 2) ? lots * 2 : prelimCams;
        const initialInstallation = requiredCameras * installAdjustment;
        const installationMaintenance = [0, initialInstallation, initialInstallation * 0.1, initialInstallation * 0.1, initialInstallation * 0.1, initialInstallation * 0.1]; //^
        const totalCams = Math.ceil(lots + ((spaces / cameraAdjustment * lots)));
        const subPrice = totalCams * cameraPrice
        const subscription = [0, subPrice, subPrice, subPrice, subPrice, subPrice]; //^
        const secondaryRevShare = ((initCitation * (1 + (citationAdjustment * 0.7))) - initCitation) * 0.3;
        const revenueSharing = [0, ((initCitation * (1 + citationAdjustment)) - initCitation) * 0.3, secondaryRevShare, secondaryRevShare, secondaryRevShare, secondaryRevShare]; //^

        // Calculate the profit, ROI and payback period
        const totalExpenses = [0, ...Array.from({ length: 5 }, (_, i) => installationMaintenance[i + 1] + subscription[i + 1] + revenueSharing[i + 1])];
        const profit = [initCitation + initPass, ...totalRevenue.slice(1).map((revenue, i) => revenue - installationMaintenance[i + 1] - subscription[i + 1] - revenueSharing[i + 1])];
        const initialOutlay = installationMaintenance[1];
        const currentProfit = profit[0];
        const year1Diff = profit[1] - currentProfit;
        const year2Diff = profit[2] - currentProfit;
        const year3Diff = profit[3] - currentProfit;

        let payBack = 0;
        if (year1Diff > initialOutlay) {
            payBack = (initialOutlay / year1Diff) * 12;
        } else if (year2Diff > initialOutlay) {
            payBack = (initialOutlay / year1Diff) * 12;
        } else if (year3Diff > initialOutlay) {
            payBack = (initialOutlay / year1Diff) * 12;
        } else {
            payBack = "36+";
        }

        setPaybackMonths(isNumber(payBack) ? Math.ceil(payBack) : "36+");

        const totalProfit = profit.reduce((acc, val) => acc + val, 0);
        const adjustedProfit = totalProfit - (profit[0] * 4);

        // Store data for display
        setData({
            yearlyCitationRevenue,
            yearlyPassRevenue,
            extraCapitalFromLabor,
            totalRevenue,
            requiredCameras,
            installationMaintenance,
            subscription,
            revenueSharing,
            totalExpenses,
            profit,
            adjustedProfit
        });
    };

    const clearResults = () => {
        setData([]);
        setInitLabor("");
        setInitCitation("");
        setInitPass("");
        setSpaces("");
        setLots("");
        setCoverage("");
    }

    const toolTip = (title, description) => {
        return (
            <div className="absolute h-[16px] w-[16px] bg-spotGray border-[2px] border-gray-400 fcc rounded-xl hover:w-40 hover:h-40 hover:rounded-xl group transition-all duration-200 ease-in-out">
                <div className="absolute top-[2px] left-[5px] rounded-full w-[2px] h-[2px] bg-gray-400 transition-all duration-100 ease-in-out group-hover:hidden"></div>
                <div className="absolute top-[5px] left-[5px] w-[2px] h-[5px] bg-gray-400 rounded-[1px] transition-all duration-100 ease-in-out group-hover:hidden"></div>
                <div className="z-50 opacity-0 group-hover:opacity-100 bg-gray-600 group-hover:delay-200 flex flex-col h-full gap-1 p-2 rounded-xl justify-start items-center transition-all text-center">
                    <div className="font-semibold hidden group-hover:flex">
                        {title}
                    </div>
                    <div className="text-sm hidden group-hover:flex">
                        {description}
                    </div>
                </div>
            </div>
        )
    }

    const toolTipInfo = {
        labor: "This is how much money you spend on labor within your parking department each year.",
        citation: "This is the yearly revenue from citations from all parking lots.",
        pass: "This is the yearly revenue from parking passes alone.",
        spaces: "This is the average number of stalls per lot across all of campus.",
        lots: "This is the number of lots that your department is in charge of.",
        coverage: "This is the estimated current camera coverage of all stalls on campus."
    }

    return (
        <div className="fcc h-fit gray-gradient w-screen font-rubik">
            <MHeader />
            <div className="h-screen fcc">
                <h1 className='text-4xl font-bold text-center text-white'>We'd be good <span className='text-spotYellow'>together.</span></h1>
                <h1 className='text-2xl font-bold text-center text-white'>Feel free to double check.</h1>
                <div className={`${scrollPosition < 0.2 ? "opacity-100" : "opacity-0"} transition-all duration-300 ease-in-out fcc`}>
                    <Arrow />
                </div>
            </div>
            <div className='fcc gap-4 w-screen rounded-3xl transition duration-300 text-white mb-8'>
                <h2 className='text-5xl font-bold text-center py-4'>ROI Calculator</h2>
                <div id='inputs' className='fcc gap-2 w-screen'>
                    <label htmlFor="calcType">Calculation Type</label>
                    <select id="calcType" className='rounded-lg h-12 w-48 text-lg pl-3 border-2 border-gray-500 bg-gray-700 cursor-pointer hover:bg-gray-600 text-white focus:border-spotYellow focus:accent-tranparent focus:outline focus:outline-transparent' value={calcType} onChange={e => setCalcType(parseFloat(e.target.value))}>
                        <option value={2}>Best Case</option>
                        <option value={1}>Average Case</option>
                        <option value={0}>Worst Case</option>
                    </select>
                    <div className="fcc my-4 gap-4">
                        <div className="frc gap-8">
                            <div className="fcc gap-1">
                                <div className="frc">
                                    <label className='mr-1 text-xs'>Yearly Citation Revenue</label>
                                    <div className="relative mb-4">
                                        {toolTip("Citation Revenue", toolTipInfo.citation)}
                                    </div>
                                </div>
                                <input type="number" className='no-spinner hover:bg-gray-700 rounded-lg h-8 w-36 text-md text-center border-2 border-gray-500 bg-transparent text-white focus:border-spotYellow focus:accent-tranparent focus:outline focus:outline-transparent' placeholder='ex. 120,000' value={initCitation} onChange={e => setInitCitation(parseFloat(e.target.value))} onFocus={e => e.target.placeholder = ''} onBlur={e => e.target.placeholder = 'ex. 120,000'} />
                            </div>
                            <div className="fcc gap-1">
                                <div className="frc gap-1">
                                    <label className='mr-1 text-xs'>Yearly Pass Revenue</label>
                                    <div className="relative mb-4">
                                        {toolTip("Pass Revenue", toolTipInfo.pass)}
                                    </div>
                                </div>
                                <input type="number" id="initPass" className='no-spinner hover:bg-gray-700 rounded-lg h-8 w-36 text-md text-center border-2 border-gray-500 bg-transparent text-white focus:border-spotYellow focus:accent-tranparent focus:outline focus:outline-transparent' placeholder='ex. 280,000' value={initPass} onFocus={e => e.target.placeholder = ''} onChange={e => setInitPass(parseFloat(e.target.value))} onBlur={e => e.target.placeholder = 'ex. 280,000'} />
                            </div>
                        </div>
                        <div className="frc gap-8">
                            <div className="fcc gap-1">
                                <div className="frc gap-1">
                                    <label className='mr-1 text-xs'>Yearly Labor Expense</label>
                                    <div className="relative mb-4">
                                        {toolTip("Labor Expense", toolTipInfo.labor)}
                                    </div>
                                </div>
                                <input type="number" id="initLabor" className='no-spinner hover:bg-gray-700 rounded-lg h-8 w-36 text-md text-center border-2 border-gray-500 bg-transparent text-white focus:border-spotYellow focus:accent-tranparent focus:outline focus:outline-transparent' placeholder='ex. 75,000' value={initLabor} onChange={e => setInitLabor(parseFloat(e.target.value))} onFocus={e => e.target.placeholder = ''} onBlur={e => e.target.placeholder = 'ex. 75,000'} />
                            </div>
                            <div className="fcc gap-1">
                                <div className="frc gap-1">
                                    <label className='mr-1 text-xs'>Average Spaces/Lot</label>
                                    <div className="relative mb-4">
                                        {toolTip("Spaces/Lot", toolTipInfo.spaces)}
                                    </div>
                                </div>
                                <input type="number" id="spaces" className='no-spinner hover:bg-gray-700 rounded-lg h-8 w-36 text-md text-center border-2 border-gray-500 bg-transparent text-white focus:border-spotYellow focus:accent-tranparent focus:outline focus:outline-transparent' placeholder='ex. 125' value={spaces} onChange={e => setSpaces(parseFloat(e.target.value))} onFocus={e => e.target.placeholder = ''} onBlur={e => e.target.placeholder = 'ex. 125'} />
                            </div>
                        </div>
                        <div className="frc gap-8">
                            <div className="fcc gap-1">
                                <div className="frc gap-1">
                                    <label className='mr-1 text-xs'>Number of Lots</label>
                                    <div className="relative mb-4">
                                        {toolTip("Number of Lots", toolTipInfo.lots)}
                                    </div>
                                </div>
                                <input type="number" id="lots" className='no-spinner hover:bg-gray-700 rounded-lg h-8 w-36 text-md text-center border-2 border-gray-500 bg-transparent text-white focus:border-spotYellow focus:accent-tranparent focus:outline focus:outline-transparent' placeholder='ex. 52' value={lots} onChange={e => setLots(parseFloat(e.target.value))} onFocus={e => e.target.placeholder = ''} onBlur={e => e.target.placeholder = 'ex. 52'} />
                            </div>
                            <div className="fcc gap-1">
                                <div className="frc gap-1">
                                    <label className='mr-1 text-xs'>Camera Coverage (%)</label>
                                    <div className="relative mb-4">
                                        {toolTip("Camera Coverage", toolTipInfo.coverage)}
                                    </div>
                                </div>
                                <input type="number" id="coverage" className='no-spinner hover:bg-gray-700 rounded-lg h-8 w-36 text-md text-center border-2 border-gray-500 bg-transparent text-white focus:border-spotYellow focus:accent-tranparent focus:outline focus:outline-transparent' placeholder='ex. 10' value={coverage} onChange={e => setCoverage(parseFloat(e.target.value))} onFocus={e => e.target.placeholder = ''} onBlur={e => e.target.placeholder = 'ex. 10'} />
                            </div>
                        </div>
                    </div>
                </div>
                <button className='h-12 w-48 rounded-lg yellow-gradient text-spotGray font-bold hover:bg-gradient-to-r hover:from-transparent hover:to-transparent hover:border-2 hover:border-spotYellow hover:text-spotYellow' onClick={calculate}>Calculate</button>
                <div id='results'>
                    {data.length !== 0 && (
                        <div className="fcc gap-4 mt-4 w-screen">
                            <hr className='w-1/3' />
                            <h2 className='w-full text-center text-4xl font-semibold mt-4'>Results</h2>
                            <p className='text-lg font-medium w-full text-center m-2 -mt-2'>Cameras Needed: {data.requiredCameras}</p>
                            <div className="fcc w-screen gap-2 mb-4">
                                <p id='answer' className='text-white text-2xl min-w-[85%] text-center font-bold bg-gray-600 border-2 border-spotYellow rounded-xl p-4 hover:scale-[101%] transition-all duration-100 ease-linear'>Initial Outlay: ${data.installationMaintenance[1].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                <p id='payback' className='text-white text-2xl min-w-[85%] text-center font-bold bg-gray-600 border-2 border-spotYellow rounded-xl p-4 hover:scale-[101%] transition-all duration-100 ease-linear'>3-Year ROI: ${data.adjustedProfit.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                <p id='payback' className='text-white text-2xl min-w-[85%] text-center font-bold bg-gray-600 border-2 border-spotYellow rounded-xl p-4 hover:scale-[101%] transition-all duration-100 ease-linear'>Payback Period: {data.adjustedProfit <= 0 || !isNumber(paybackMonths) ? "36+ Months" : paybackMonths > 1 ? `${paybackMonths} months` : `${paybackMonths} month`}</p>
                            </div>
                            <div className=" overflow-x-auto w-screen py-4 px-8">
                                <table className='table-auto w-screen text-left border-2'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th className='border p-2'>Current</th>
                                            <th className='border p-2'>Year 1</th>
                                            <th className='border p-2'>Year 2</th>
                                            <th className='border p-2'>Year 3</th>
                                            <th className='border p-2'>Year 4</th>
                                            <th className='border p-2'>Year 5</th>
                                        </tr>
                                    </thead>
                                    <tbody className='border'>
                                        <tr className='border bg-gray-700'>
                                            <td className='font-bold px-2'>Yearly Citation Revenue</td>
                                            {data.yearlyCitationRevenue.map((val, i) => (
                                                <td className='border p-2 hover:bg-gray-600' key={i}>${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            ))}
                                        </tr>
                                        <tr className='border bg-gray-600'>
                                            <td className='font-bold px-2'>Yearly Pass Revenue</td>
                                            {data.yearlyPassRevenue.map((val, i) => (
                                                <td className='border p-2 hover:bg-gray-700' key={i}>${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            ))}
                                        </tr>
                                        <tr className='border bg-gray-700'>
                                            <td className='font-bold px-2'>Recouped Labor Expense</td>
                                            {data.extraCapitalFromLabor.map((val, i) => (
                                                <td className='border p-2 hover:bg-gray-600' key={i}>${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            ))}
                                        </tr>
                                        <tr className='border border-t-4 bg-gray-600'>
                                            <td className='font-bold px-2'>Total Revenue</td>
                                            {data.totalRevenue.map((val, i) => (
                                                <td className='border border-t-4 p-2 font-bold hover:bg-gray-700' key={i}>${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            ))}
                                        </tr>
                                        <tr className='border bg-gray-700'>
                                            <td className='font-bold px-2'>Installation/Maintenance</td>
                                            {data.installationMaintenance.map((val, i) => (
                                                <td className='border p-2 hover:bg-gray-600' key={i}>${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            ))}
                                        </tr>
                                        <tr className='border bg-gray-600'>
                                            <td className='font-bold px-2'>Subscription</td>
                                            {data.subscription.map((val, i) => (
                                                <td className='border p-2 hover:bg-gray-700' key={i}>${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            ))}
                                        </tr>
                                        <tr className='border bg-gray-700'>
                                            <td className='font-bold px-2'>Revenue Sharing</td>
                                            {data.revenueSharing.map((val, i) => (
                                                <td className='border p-2 hover:bg-gray-600' key={i}>${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            ))}
                                        </tr>
                                        <tr className='border border-t-4 bg-gray-600'>
                                            <td className='font-bold px-2'>Total Expenses</td>
                                            {data.totalExpenses.map((val, i) => (
                                                <td className='border p-2 border-t-4 hover:bg-gray-700' key={i}>${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            ))}
                                        </tr>
                                        <tr className='border border-t-4 bg-gray-700'>
                                            <td className='font-bold px-2'>Profit</td>
                                            {data.profit.map((val, i) => (
                                                <td className={`border p-2 font-bold border-t-4 hover:bg-gray-600 ${val > data.profit[0] && "bg-green-200/40"}`} key={i}>${val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <button className='h-12 w-48 rounded-lg light-gray-gradient text-spotGray font-bold hover:bg-gradient-to-r hover:from-transparent hover:to-transparent hover:border-2 hover:border-gray-400 hover:text-gray-400' onClick={clearResults}>Clear Results</button>
                        </div>
                    )}
                </div>
            </div >
            <MFooter textColor={"text-white"} />
        </div>
    );
};
