import React from "react";
import { Link } from "react-router-dom";
import { FaSquareXTwitter, FaLinkedin } from "react-icons/fa6";
import Tilt from 'react-parallax-tilt';
import "./footer.css"

export const Footer = ({ bg, textColor }) => {
    const footerLinks = [
        { title: "LinkedIn", url: "https://www.linkedin.com/company/spotparking/", icon: <FaLinkedin /> },
        { title: "X", url: "https://twitter.com/spotparking_", icon: <FaSquareXTwitter /> },
    ];

    return (
        <div className="footer pb-2" style={{ backgroundColor: bg }}>
            <div className="footer-icon-container mb-2">
                {footerLinks.map((link, index) => (
                    <Tilt key={index} scale={1.1} tiltMaxAngleX={5} tiltMaxAngleY={5}>
                        <Link to={link.url} className="logo-link text-4xl" style={{ color: textColor }}>
                            {link.icon}
                        </Link>
                    </Tilt>
                ))}
            </div>
            <p className="copyright" style={{ color: textColor }} >&copy; 2024 Spot Parking. All rights reserved.</p>
        </div>
    )
}