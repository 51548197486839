import React, { useState } from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";

const InfinityScroll = () => {
    const plates = {
        "Car Detected: SUV - #AM5051": true,
        "Car Detected: Truck - #CK7484": false,
        "Car Detected: SUV - #SM9513": false,
        "Car Detected: Sedan - #WW5814": true,
        "Car Detected: Sedan - #NX0065": false,
        "Car Detected: SUV - #FR8224": true,
        "Car Detected: Sedan - #LY4714": false,
        "Car Detected: SUV - #DR5175": false,
        "Car Detected: SUV - #BS9163": true,
        "Car Detected: Truck - #3DN4155": true,
        "Car Detected: SUV - #KZ4971": false,
        "Car Detected: SUV - #JQ9825": true,
        "Car Detected: SUV - #FO4213": false,
        "Car Detected: Sedan - #YJ7992": true,
        "Car Detected: SUV - #JF4129": false,
        "Car Detected: SUV - #ZD7957": false,
        "Car Detected: SUV - #FY4393": false,
        "Car Detected: SUV - #UQ1800": true,
        "Car Detected: SUV - #FI7769": false,
        "Car Detected: Sedan - #US6779": false,
        "Car Detected: SUV - #HG9740": true,
        "Car Detected: Truck - #CH7031": true,
        "Car Detected: SUV - #DK1723": false,
        "Car Detected: SUV - #OR9980": false,
        "Car Detected: Truck - #CX9095": false,
        "Car Detected: SUV - #XN7221": true,
        "Car Detected: SUV - #YC1371": false,
        "Car Detected: SUV - #HS3176": true,
        "Car Detected: Truck - #QT7461": false,
        "Car Detected: SUV - #SG5341": true,
        "Car Detected: SUV - #YT0013": true,
        "Car Detected: Sedan - #MA2297": false,
        "Car Detected: SUV - #LP7524": false,
        "Car Detected: SUV - #OR6134": true,
        "Car Detected: Sedan - #NJ6869": false,
        "Car Detected: SUV - #XL1704": false,
        "Car Detected: SUV - #IK9234": true,
        "Car Detected: Truck - #DL6732": false,
        "Car Detected: SUV - #PD6744": true,
        "Car Detected: Sedan - #CQ5057": true,
        "Car Detected: SUV - #AZ3461": false,
        "Car Detected: SUV - #UV9822": true,
        "Car Detected: Truck - #JL5881": false,
        "Car Detected: SUV - #TT2732": false,
        "Car Detected: SUV - #BC1190": false,
        "Car Detected: SUV - #RW4664": true,
        "Car Detected: Sedan - #TM0545": true,
        "Car Detected: SUV - #KP9940": false,
        "Car Detected: SUV - #EM6512": false,
        "Car Detected: Truck - #MQ2211": true,
        "Car Detected: SUV - #AM5052": true,
        "Car Detected: SUV - #CK7485": false,
        "Car Detected: SUV - #SM9514": false,
        "Car Detected: Sedan - #WW5815": true,
        "Car Detected: SUV - #NX0066": false,
        "Car Detected: SUV - #FR8225": true,
        "Car Detected: SUV - #LY4715": false,
        "Car Detected: Truck - #DR5176": false,
        "Car Detected: SUV - #BS9164": true,
        "Car Detected: SUV - #DN4156": true,
        "Car Detected: SUV - #KZ4972": false,
        "Car Detected: SUV - #JQ9826": true,
        "Car Detected: SUV - #FO4214": false,
        "Car Detected: SUV - #YJ7993": true,
        "Car Detected: SUV - #JF4130": false,
        "Car Detected: SUV - #ZD7958": false,
        "Car Detected: SUV - #FY4394": false,
        "Car Detected: Sedan - #UQ1801": true,
        "Car Detected: SUV - #FI7770": false,
        "Car Detected: SUV - #US6780": false,
        "Car Detected: SUV - #HG9741": true,
        "Car Detected: SUV - #CH7032": true,
        "Car Detected: Sedan - #DK1724": false,
        "Car Detected: SUV - #OR9981": false,
        "Car Detected: Sedan - #CX9096": false,
        "Car Detected: SUV - #XN7222": true,
        "Car Detected: SUV - #YC1372": false,
        "Car Detected: Truck - #HS3177": true,
        "Car Detected: SUV - #QT7462": false,
        "Car Detected: Sedan - #SG5342": true,
        "Car Detected: SUV - #YT0014": true,
        "Car Detected: SUV - #MA2298": false,
        "Car Detected: SUV - #LP7525": false,
        "Car Detected: SUV - #OR6135": true,
        "Car Detected: SUV - #NJ6870": false,
        "Car Detected: SUV - #XL1705": false,
        "Car Detected: SUV - #IK9235": true,
        "Car Detected: Truck - #DL6733": false,
        "Car Detected: SUV - #PD6745": true,
        "Car Detected: Sedan - #CQ5058": true,
        "Car Detected: SUV - #AZ3462": false,
        "Car Detected: SUV - #UV9823": true,
        "Car Detected: SUV - #JL5882": false,
        "Car Detected: SUV - #TT2733": false,
        "Car Detected: SUV - #BC1191": false,
        "Car Detected: Sedan - #RW4665": true,
        "Car Detected: SUV - #TM0546": true,
        "Car Detected: SUV - #KP9941": false,
        "Car Detected: Truck - #EM6513": false,
        "Car Detected: SUV - #MQ2212": true,
        "Car Detected: SUV - #AM5053": true,
        "Car Detected: Sedan - #CK7486": false,
        "Car Detected: Truck - #SM9515": false,
        "Car Detected: SUV - #WW5816": true,
        "Car Detected: SUV - #NX0067": false,
        "Car Detected: SUV - #FR8226": true,
        "Car Detected: SUV - #LY4716": false,
        "Car Detected: Sedan - #DR5177": false,
        "Car Detected: SUV - #BS9165": true,
        "Car Detected: SUV - #DN4157": true,
        "Car Detected: SUV - #KZ4973": false,
        "Car Detected: Truck - #JQ9827": true,
        "Car Detected: SUV - #FO4215": false,
        "Car Detected: SUV - #YJ7994": true,
        "Car Detected: Sedan - #JF4131": false,
        "Car Detected: SUV - #ZD7959": false,
        "Car Detected: SUV - #FY4395": false,
        "Car Detected: SUV - #UQ1802": true,
        "Car Detected: SUV - #FI7771": false,
        "Car Detected: SUV - #US6781": false,
        "Car Detected: Truck - #HG9742": true,
        "Car Detected: SUV - #CH7033": true,
        "Car Detected: Sedan - #DK1725": false,
        "Car Detected: SUV - #OR9982": false,
        "Car Detected: SUV - #CX9097": false,
        "Car Detected: SUV - #XN7223": true,
        "Car Detected: Sedan - #YC1373": false,
        "Car Detected: SUV - #HS3178": true,
        "Car Detected: Sedan - #QT7463": false,
        "Car Detected: SUV - #SG5343": true,
        "Car Detected: Truck - #YT0015": true,
        "Car Detected: SUV - #MA2299": false,
        "Car Detected: SUV - #LP7526": false,
        "Car Detected: SUV - #OR6136": true,
        "Car Detected: Truck - #NJ6871": false,
        "Car Detected: SUV - #XL1706": false,
        "Car Detected: SUV - #IK9236": true,
        "Car Detected: Sedan - #DL6734": false,
        "Car Detected: Truck - #PD6746": true,
        "Car Detected: SUV - #CQ5059": true,
        "Car Detected: Sedan - #AZ3463": false,
        "Car Detected: SUV - #UV9824": true,
        "Car Detected: Truck - #JL5883": false,
        "Car Detected: SUV - #TT2734": false,
        "Car Detected: SUV - #BC1192": false,
        "Car Detected: SUV - #RW4666": true,
        "Car Detected: SUV - #TM0547": true,
        "Car Detected: Truck - #KP9942": false,
        "Car Detected: SUV - #EM6514": false,
        "Car Detected: Sedan - #MQ2213": true,
        "Car Detected: SUV - #AM5054": true,
        "Car Detected: SUV - #CK7487": false,
        "Car Detected: SUV - #AM5051": true,
        "Car Detected: Truck - #CK7484": false,
        "Car Detected: SUV - #SM9513": false,
        "Car Detected: Sedan - #WW5814": true,
        "Car Detected: Sedan - #NX0065": false,
        "Car Detected: SUV - #FR8224": true,
        "Car Detected: Sedan - #LY4714": false,
        "Car Detected: SUV - #DR5175": false,
        "Car Detected: SUV - #BS9163": true,
        "Car Detected: Truck - #3DN4155": true,
        "Car Detected: SUV - #KZ4971": false,
        "Car Detected: SUV - #JQ9825": true,
        "Car Detected: SUV - #FO4213": false,
        "Car Detected: Sedan - #YJ7992": true,
        "Car Detected: SUV - #JF4129": false,
        "Car Detected: SUV - #ZD7957": false,
        "Car Detected: SUV - #FY4393": false,
        "Car Detected: SUV - #UQ1800": true,
        "Car Detected: SUV - #FI7769": false,
        "Car Detected: Sedan - #US6779": false,
        "Car Detected: SUV - #HG9740": true,
        "Car Detected: Truck - #CH7031": true,
        "Car Detected: SUV - #DK1723": false,
        "Car Detected: SUV - #OR9980": false,
        "Car Detected: Truck - #CX9095": false,
        "Car Detected: SUV - #XN7221": true,
        "Car Detected: SUV - #YC1371": false,
        "Car Detected: SUV - #HS3176": true,
        "Car Detected: Truck - #QT7461": false,
        "Car Detected: SUV - #SG5341": true,
        "Car Detected: SUV - #YT0013": true,
        "Car Detected: Sedan - #MA2297": false,
        "Car Detected: SUV - #LP7524": false,
        "Car Detected: SUV - #OR6134": true,
        "Car Detected: Sedan - #NJ6869": false,
        "Car Detected: SUV - #XL1704": false,
        "Car Detected: SUV - #IK9234": true,
        "Car Detected: Truck - #DL6732": false,
        "Car Detected: SUV - #PD6744": true,
        "Car Detected: Sedan - #CQ5057": true,
        "Car Detected: SUV - #AZ3461": false,
        "Car Detected: SUV - #UV9822": true,
        "Car Detected: Truck - #JL5881": false,
        "Car Detected: SUV - #TT2732": false,
        "Car Detected: SUV - #BC1190": false,
        "Car Detected: SUV - #RW4664": true,
        "Car Detected: Sedan - #TM0545": true,
        "Car Detected: SUV - #KP9940": false,
        "Car Detected: SUV - #EM6512": false,
        "Car Detected: Truck - #MQ2211": true,
        "Car Detected: SUV - #AM5052": true,
        "Car Detected: SUV - #CK7485": false,
        "Car Detected: SUV - #SM9514": false,
        "Car Detected: Sedan - #WW5815": true,
        "Car Detected: SUV - #NX0066": false,
        "Car Detected: SUV - #FR8225": true,
        "Car Detected: SUV - #LY4715": false,
        "Car Detected: Truck - #DR5176": false,
        "Car Detected: SUV - #BS9164": true,
        "Car Detected: SUV - #DN4156": true,
        "Car Detected: SUV - #KZ4972": false,
        "Car Detected: SUV - #JQ9826": true,
        "Car Detected: SUV - #FO4214": false,
        "Car Detected: SUV - #YJ7993": true,
        "Car Detected: SUV - #JF4130": false,
        "Car Detected: SUV - #ZD7958": false,
        "Car Detected: SUV - #FY4394": false,
        "Car Detected: Sedan - #UQ1801": true,
        "Car Detected: SUV - #FI7770": false,
        "Car Detected: SUV - #US6780": false,
        "Car Detected: SUV - #HG9741": true,
        "Car Detected: SUV - #CH7032": true,
        "Car Detected: Sedan - #DK1724": false,
        "Car Detected: SUV - #OR9981": false,
        "Car Detected: Sedan - #CX9096": false,
        "Car Detected: SUV - #XN7222": true,
        "Car Detected: SUV - #YC1372": false,
        "Car Detected: Truck - #HS3177": true,
        "Car Detected: SUV - #QT7462": false,
        "Car Detected: Sedan - #SG5342": true,
        "Car Detected: SUV - #YT0014": true,
        "Car Detected: SUV - #MA2298": false,
        "Car Detected: SUV - #LP7525": false,
        "Car Detected: SUV - #OR6135": true,
        "Car Detected: SUV - #NJ6870": false,
        "Car Detected: SUV - #XL1705": false,
        "Car Detected: SUV - #IK9235": true,
        "Car Detected: Truck - #DL6733": false,
        "Car Detected: SUV - #PD6745": true,
        "Car Detected: Sedan - #CQ5058": true,
        "Car Detected: SUV - #AZ3462": false,
        "Car Detected: SUV - #UV9823": true,
        "Car Detected: SUV - #JL5882": false,
        "Car Detected: SUV - #TT2733": false,
        "Car Detected: SUV - #BC1191": false,
        "Car Detected: Sedan - #RW4665": true,
        "Car Detected: SUV - #TM0546": true,
        "Car Detected: SUV - #KP9941": false,
        "Car Detected: Truck - #EM6513": false,
        "Car Detected: SUV - #MQ2212": true,
        "Car Detected: SUV - #AM5053": true,
        "Car Detected: Sedan - #CK7486": false,
        "Car Detected: Truck - #SM9515": false,
        "Car Detected: SUV - #WW5816": true,
        "Car Detected: SUV - #NX0067": false,
        "Car Detected: SUV - #FR8226": true,
        "Car Detected: SUV - #LY4716": false,
        "Car Detected: Sedan - #DR5177": false,
        "Car Detected: SUV - #BS9165": true,
        "Car Detected: SUV - #DN4157": true,
        "Car Detected: SUV - #KZ4973": false,
        "Car Detected: Truck - #JQ9827": true,
        "Car Detected: SUV - #FO4215": false,
        "Car Detected: SUV - #YJ7994": true,
        "Car Detected: Sedan - #JF4131": false,
        "Car Detected: SUV - #ZD7959": false,
        "Car Detected: SUV - #FY4395": false,
        "Car Detected: SUV - #UQ1802": true,
        "Car Detected: SUV - #FI7771": false,
        "Car Detected: SUV - #US6781": false,
        "Car Detected: Truck - #HG9742": true,
        "Car Detected: SUV - #CH7033": true,
        "Car Detected: Sedan - #DK1725": false,
        "Car Detected: SUV - #OR9982": false,
        "Car Detected: SUV - #CX9097": false,
        "Car Detected: SUV - #XN7223": true,
        "Car Detected: Sedan - #YC1373": false,
        "Car Detected: SUV - #HS3178": true,
        "Car Detected: Sedan - #QT7463": false,
        "Car Detected: SUV - #SG5343": true,
        "Car Detected: Truck - #YT0015": true,
        "Car Detected: SUV - #MA2299": false,
        "Car Detected: SUV - #LP7526": false,
        "Car Detected: SUV - #OR6136": true,
        "Car Detected: Truck - #NJ6871": false,
        "Car Detected: SUV - #XL1706": false,
        "Car Detected: SUV - #IK9236": true,
        "Car Detected: Sedan - #DL6734": false,
        "Car Detected: Truck - #PD6746": true,
        "Car Detected: SUV - #CQ5059": true,
        "Car Detected: Sedan - #AZ3463": false,
        "Car Detected: SUV - #UV9824": true,
        "Car Detected: Truck - #JL5883": false,
        "Car Detected: SUV - #TT2734": false,
        "Car Detected: SUV - #BC1192": false,
        "Car Detected: SUV - #RW4666": true,
        "Car Detected: SUV - #TM0547": true,
        "Car Detected: Truck - #KP9942": false,
        "Car Detected: SUV - #EM6514": false,
        "Car Detected: Sedan - #MQ2213": true,
        "Car Detected: SUV - #AM5054": true,
        "Car Detected: SUV - #CK7487": false,
    }

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [gradientPosition, setGradientPosition] = useState({ x: "", y: "" });

    const handleMouseMove = (e, index) => {
        if (hoveredIndex !== index) return; // Apply gradient only to the hovered box
        const rect = e.currentTarget.getBoundingClientRect();
        const x = ((e.clientX - rect.left) / rect.width) * 100;
        const y = ((e.clientY - rect.top) / rect.height) * 100;
        setGradientPosition({ x: `${x}%`, y: `${y}%` });
    };

    return (
        <div className="flex h-screen w-screen items-center justify-center ml-48">
            <div className="relative w-full max-w-screen-lg">
                <div className="pointer-events-none absolute -top-1 z-10 h-20 w-full bg-gradient-to-b from-transparent"></div>
                <div className="pointer-events-none absolute -bottom-1 z-10 h-20 w-full bg-gradient-to-t from-transparent"></div>
                <div className="pointer-events-none absolute -left-1 z-10 h-full w-20 bg-gradient-to-r from-transparent"></div>
                <div className="pointer-events-none absolute -right-1 z-10 h-full w-20 bg-gradient-to-l from-transparent"></div>

                <div className="mx-auto grid h-[2000px] w-[300px] animate-skew-scroll grid-cols-1 gap-x-8 gap-y-4 sm:w-[600px] sm:grid-cols-2">
                    {Object.entries(plates).map(([plate, checked], index) => {
                        return (
                            <div
                                key={index}
                                className="relative flex cursor-pointer items-center rounded-md border border-gray-100 p-5 w-[300px] shadow-md transition-all hover:-translate-y-1 hover:translate-x-1 hover:scale-[1.025] hover:shadow-xl"
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                                onMouseMove={(e) => handleMouseMove(e, index)}
                                style={{
                                    "--x": hoveredIndex === index ? gradientPosition.x : "80%",
                                    "--y": hoveredIndex === index ? gradientPosition.y : "80%",
                                }}
                            >
                                {checked ? (
                                    <FaCircleCheck className="text-green-300" size={24} />
                                ) : (
                                    <MdCancel className="text-red-400" size={24} />
                                )}
                                <div className="flex flex-row">
                                    <p className="text-white ml-2 mr-1 text-sm">{plate}</p>
                                </div>
                                {hoveredIndex === index && (
                                    <div
                                        className="pointer-events-none absolute top-0 left-0 w-full h-full rounded-md"
                                        style={{
                                            // background: `radial-gradient(circle at var(--x) var(--y), rgba(254, 240, 138, 0.1) 50%, transparent 100%)`,
                                            zIndex: -1,
                                        }}
                                    ></div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default InfinityScroll;