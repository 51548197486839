import React, { useEffect } from "react";
import { postData } from "./fetcher";

const Download = () => {
    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        const isMacOS = /Macintosh/.test(navigator.userAgent) && !window.MSStream;
        const downloadUrl = isIOS || isMacOS
            ? "https://apps.apple.com/us/app/spot-nav/id6736955321"
            : "https://play.google.com/store/apps/details?id=com.navnative";

        const sendCount = async () => {
            await postData("/general/incrementQRCounter", {});
            window.location.href = downloadUrl;
        }

        sendCount();
    }, []);

    return (
        <div className="p-20 font-sans">
        </div>
    );
}

export default Download;